import React, { useEffect } from 'react';
import { useAuth } from 'hooks';

export interface LogoutProps {}

const Logout: React.FC<LogoutProps> = () => {
    const { logout } = useAuth();
    useEffect(() => {
        logout(() => {});
    }, [logout]);
    return <></>;
};

export default Logout;
