import React from 'react';
import type { ResetFormHelpers, ResetFormValues } from 'components/organisms';
import { ResetForm } from 'components/organisms';
import { AuthenticationTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';

export interface ResetPageProps {
    initialRegisterFormValues: ResetFormValues;
    onRegisterFormSubmit: (values: ResetFormValues, helpers: ResetFormHelpers) => void;
}

const ResetPage: React.FC<ResetPageProps> = props => (
    <AuthenticationTemplate>
        <div className='m-3'>
            <img src={process.env.REACT_APP_LOGO} style={{ marginLeft: 15, marginTop:15 }} width={"40px"} alt='logo' />
        </div>
        <Container>
            <Row className="justify-content-center">
                <Col lg="5">
                    <Card className="shadow-lg border-0 mt-5">
                        <CardHeader>
                            <h3 className="text-center font-weight-light my-4">パスワードを再設定する</h3>
                        </CardHeader>
                        <CardBody>
                            <ResetForm
                                initialValues={props.initialRegisterFormValues}
                                onSubmit={props.onRegisterFormSubmit}
                            />
                        </CardBody>
                        <CardFooter className="text-center py-3">
                            <Link className="small" to="/login">
                                ログインページへ
                            </Link>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
    </AuthenticationTemplate>
);

export default ResetPage;
