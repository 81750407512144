import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface DetailPDFPreviewProps {
    open: boolean;
    title: string;
    path: string;
    handleClose: () => void;
};

const DetailPDFPreview: React.FC<DetailPDFPreviewProps> = ({open, title, path, handleClose}) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [flag, setFlag] = useState<boolean>(false);

    useEffect(()=>{
        setNumPages(1);
        setPageNumber(1);
        setFlag(false);
    }, [open])

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setFlag(true);
        setNumPages(numPages);
    }

    const nextPage = () => { 
        if(flag){
            setPageNumber((pre)=>pre+1) ;
        }
    };
    const prePage = () => { 
        if(flag){
            setPageNumber((pre)=>pre-1);
        }
    };
    return <div>
        <Modal isOpen={open} toggle={handleClose} 
            className={"modal-fullscreen-sm-down modal-dialog-centered"}>
            <ModalHeader toggle={handleClose}>
                {title}
            </ModalHeader>
            <ModalBody style={{ overflowY: "auto"}} className="border">
                { !!path && 
                    <Document file={`${process.env.REACT_APP_File_URI}${path}`} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                }
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Button color="dark" disabled={pageNumber===1} onClick={prePage}>戻る</Button>
                {`${pageNumber} / ${numPages}`}
                <Button color="dark" disabled={pageNumber===numPages} onClick={nextPage}>次に</Button>
            </ModalFooter>
        </Modal>
    </div>
}

export default DetailPDFPreview;