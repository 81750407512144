import React,{ ChangeEvent, useState, useRef } from 'react';
import { Container, Input, Button, Card, Table, FormGroup, Label } from 'reactstrap';
import { DefaultTemplate } from 'components/templates';
import { faAdd, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    LabelDecoration, CustomizeCloseBtn, CustomizeAddButton, PDFViewer 
} from "components/organisms";
import { CustomerManagementHelper } from 'helper';
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
const { v4: uuidv4 } = require('uuid');             // uuidv4(),

interface EcocuteType {
    type: boolean | undefined;
}

export interface AddNewCustomerPageProps {};

interface PastCustomerInfoProps {
    firstName: string;
    lastName: string;
    furiganaFirstName: string;
    furiganaLastName: string;
}

interface FileProps {
    id: string;
    filename: string;
    filetype: string;
    file: File | null;
    filesize: string;
    changedfile: string;
}

interface PreViewProps {
    open: boolean;
    title: string;
    pdfFile: File|null;
}

interface ECFAddedDateProps {
    ecocuteModel: string;
    updateAt: Date | null; // Allow null
}

interface CustomerInfoProps {
    firstName: string;
    lastName: string;
    furiganaFirstName: string;
    furiganaLastName: string;
    pastCustomers: Array<PastCustomerInfoProps>;
    address: string;
    zipCode: string;
    googleMapCode: string;
    phoneNumber: string;
    contractDate: Date | null; // Allow null
    constructionDate: Date | null; // Allow null
    category: string;
    salesType: string;
    ECFAddedDate: Array<ECFAddedDateProps>;
    pdfFiles: Array<FileProps>;
    imageFiles: Array<FileProps>;
    note: string;
}

const AddNewCustomerPage: React.FC<AddNewCustomerPageProps> = () => {
    const pdfFilesSelect = useRef<HTMLInputElement>(null);
    const imageFilesSelect = useRef<HTMLInputElement>(null);

    const [ customerInfo, setCustomerInfo ] = useState<CustomerInfoProps>({
        firstName:"", 
        lastName: "",
        furiganaFirstName: "",
        furiganaLastName: "",
        pastCustomers: [{
            firstName: "",
            lastName: '',
            furiganaFirstName: '',
            furiganaLastName: ''
        }],
        address: "",
        zipCode: "",
        googleMapCode: "",
        phoneNumber: "",
        contractDate: new Date(),
        constructionDate: new Date(),
        category: "工事",
        salesType: "エコキュート単体",
        ECFAddedDate: [{
            ecocuteModel: '',
            updateAt: null
        }],
        pdfFiles: [],
        imageFiles: [],
        note: ""
    });

    const [ecocuteType, setEcocuteType] = useState<EcocuteType[]>([{ type: false },]);

    const [ preView, setPreView ] = useState<PreViewProps>({
        open: false, 
        title: "",
        pdfFile: null
    });
    // -----------------------------------------
    const addCustomerInfo = () => {
        // Create a new PastCustomerInfoProps object
        const newPastCustomerInfo: PastCustomerInfoProps = {
            firstName: "",
            lastName: "",
            furiganaFirstName: "",
            furiganaLastName: "",
        };

        // Create a copy of the existing pastCustomers array
        const updatedPastCustomers = [...customerInfo.pastCustomers];

        // Add the new PastCustomerInfoProps object to the copied array
        updatedPastCustomers.push(newPastCustomerInfo);

        // Update the customerInfo state with the copied array
        setCustomerInfo({
            ...customerInfo,
            pastCustomers: updatedPastCustomers,
        });
    }

    const removeCustomerInfo = (index: number) =>{
        const updatedPastCustomers = [...customerInfo.pastCustomers];
        updatedPastCustomers.splice(index, 1);
        setCustomerInfo({
            ...customerInfo,
            pastCustomers: updatedPastCustomers,
        });
    }
    // -----------------------------------------

    const addPastContractDate = () => {
        // Create a new UpdateAtProps object
        const newPastCustomerInfo: ECFAddedDateProps = {
            ecocuteModel:"",
            updateAt: null,
        };

        // Create a copy of the existing ECFAddedDate array
        const updatedPastContractDate = [...customerInfo.ECFAddedDate];

        // Add the new PastCustomerInfoProps object to the copied array
        updatedPastContractDate.push(newPastCustomerInfo);

        // Update the customerInfo state with the copied array
        setCustomerInfo({
            ...customerInfo,
            ECFAddedDate: updatedPastContractDate,
        });
    }

    const removePastContractDate = (index: number) => {
        const updatedPastContractDate = [...customerInfo.ECFAddedDate];
        updatedPastContractDate.splice(index, 1);
        setCustomerInfo({
            ...customerInfo,
            ECFAddedDate: updatedPastContractDate,
        });
    }
    // -----------------------------------------
    const filesizes = (bytes: number, decimals = 2): string => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    // -----------------------------------------------------------
    const PDFInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        // For Multiple File Input
        if (e.target.files) {
            const filesArray: FileProps[] = [];
            const files = Array.from(e.target.files);

            files.forEach((file) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const selectedFile: FileProps = {
                        id: uuidv4(),
                        filename: file.name,
                        filetype: file.type,
                        file: file,
                        filesize: filesizes(file.size),
                        changedfile: reader.result as string,
                    };

                    filesArray.push(selectedFile);
                    console.log(selectedFile);
                    // Check if all files are processed before updating the state
                    if (filesArray.length === files.length) {
                        setCustomerInfo((prevInfo) => ({
                            ...prevInfo,
                            pdfFiles: [...prevInfo.pdfFiles, ...filesArray],
                        }));
                    }
                };

                reader.readAsDataURL(file);
            });
        }
    };

    const DeleteSelectedPDF = (id: string, filename: string) => {
        const result = customerInfo.pdfFiles.filter((data) => data.id !== id);
        setCustomerInfo({
            ...customerInfo,
            pdfFiles: result,
        });
        toast.success(`「${filename}」を削除しました`, {
            position: "top-center",
            autoClose: 600,
        });
    };

    const AddPDFFile = () => {
        if (pdfFilesSelect.current) {
            pdfFilesSelect.current.click();
        }
    }

    const closePrePDFPreView =() =>{
        setPreView({
            open: false, 
            title: "",
            pdfFile: null
        })
    }

    const openPDFPreView = (pdfFile: File | null, filename: string) => {
        if (!!pdfFile) {
            setPreView({
              open: true,
              title: filename,
              pdfFile: pdfFile
            });
        }
    }
    // --------------------------------------------
    const AddImageFile = () => {
        if (imageFilesSelect.current) {
            imageFilesSelect.current.click();
        }
    }
    
    const ImageInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        // For Multiple File Input
        if (e.target.files) {
            const filesArray: FileProps[] = [];
            const files = Array.from(e.target.files);

            files.forEach((file) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const selectedFile: FileProps = {
                        id: uuidv4(),
                        filename: file.name,
                        filetype: file.type,
                        file: file,
                        filesize: filesizes(file.size),
                        changedfile: reader.result as string,
                    };

                    filesArray.push(selectedFile);

                    // Check if all files are processed before updating the state
                    if (filesArray.length === files.length) {
                        setCustomerInfo((prevInfo) => ({
                            ...prevInfo,
                            imageFiles: [...prevInfo.imageFiles, ...filesArray],
                        }));
                    }
                };

                reader.readAsDataURL(file);
            });
        }
    };

    const DeleteSelectedImage = (id: string, index: number) => {
        const result = customerInfo.imageFiles.filter((data) => data.id !== id);
        setCustomerInfo({
            ...customerInfo,
            imageFiles: result,
        });
        toast.success(`${index+1}番の画像が削除された。`, {
            position: "top-center",
            autoClose: 600,
        });
    };
    // ------------------------------------------
    const saveData = () => {
        const validationData = checkValidation();
        if(validationData){
            CustomerManagementHelper.createCustomerData(customerInfo)
            .then((res: any) => {
                window.location.href= '/'
                toast.success(`データがアーカイブされました。`, {
                    position: "top-center",
                    autoClose: 1000,
                });
            })
            .catch((err: any) => {
                toast.error(`エラーが発生しました。 開発者に連絡してください。`, {
                    position: "top-center",
                    autoClose: 1000,
                });
            })
        }
    }

    const checkValidation = () => {
        if(!customerInfo.firstName||!customerInfo.lastName){
            errorToast('名前');
            return false;
        }
        if(!customerInfo.furiganaFirstName||!customerInfo.furiganaLastName){
            errorToast('フリガナ');
            return false;
        }
        if(!customerInfo.zipCode){
            errorToast('郵便番号');
            return false;
        }
        if(!customerInfo.address){
            errorToast('住所');
            return false;
        }
        if(!customerInfo.googleMapCode){
            errorToast('GoogleMap');
            return false;
        }
        if(!customerInfo.phoneNumber){
            errorToast('電話番号');
            return false;
        }
        if(!customerInfo.salesType){
            errorToast('販売種別');
            return false;
        }
        if(!checkObjectArray(customerInfo.ECFAddedDate)){
            errorToast('エコキュートの型式');
            return false;
        }
        return true;
    }

    function checkObjectArray(array: ECFAddedDateProps[]): boolean {
        if( array.length > 1 ){
            for (let obj of array) {
                if (!obj.ecocuteModel || !obj.updateAt) {
                    return false;
                }
            }
        } else {
            if((!!array[0].ecocuteModel && !!array[0].updateAt) || (!array[0].ecocuteModel && !array[0].updateAt)) return true;
            else return false;
        }
        return true;
    }

    const errorToast = (msg: string) => {
        toast.error(`「${msg}」を確認してください。`, {
            position: "top-center",
            autoClose: 1000,
        });
    }

    return (
        <DefaultTemplate>
            <Container>
                <div className='row grid d-flex justify-content-center'>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"名前"} />
                        <div className="row grid d-flex justify-content-center mt-1">
                            <div className="col-6 g-col-6">
                                <Input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    value={customerInfo.firstName}
                                    onChange={(e)=>{setCustomerInfo({...customerInfo, firstName: e.target.value})}}
                                />
                            </div>
                            <div className="col-6 g-col-6">
                                <Input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    value={customerInfo.lastName}
                                    onChange={(e)=>{setCustomerInfo({...customerInfo, lastName: e.target.value})}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"フリガナ"} />
                        <div className="row grid d-flex justify-content-center mt-1">
                            <div className="col-6 g-col-6">
                                <Input
                                    id="furiganaFirstName"
                                    name="furiganaFirstName"
                                    type="text"
                                    value={customerInfo.furiganaFirstName}
                                    onChange={(e)=>{setCustomerInfo({...customerInfo, furiganaFirstName: e.target.value})}}
                                />
                            </div>
                            <div className="col-6 g-col-6">
                                <Input
                                    id="furiganaLastName"
                                    name="furiganaLastName"
                                    type="text"
                                    value={customerInfo.furiganaLastName}
                                    onChange={(e)=>{setCustomerInfo({...customerInfo, furiganaLastName: e.target.value})}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------------------------- customer add  ----------------------- */}
                { customerInfo.pastCustomers.map((item, index)=>(
                    <div key={`customerInfo${index}`} className='row grid d-flex justify-content-center'>
                        <div className='col-12 m-2 position-relative'>
                            { customerInfo.pastCustomers.length > 1 &&
                                <CustomizeCloseBtn 
                                    id={`pastContractorInformation${index}`} 
                                    text={`過去の契約者情報 (${index+1})の削除`} 
                                    index={index}
                                    onClick={(e)=>{removeCustomerInfo(e)}}
                                />
                            }
                            <LabelDecoration label={`過去の契約者情報 (${index+1})`} />
                            <div className="row grid d-flex justify-content-center mt-1">
                                <div className="col-6 g-col-6">
                                    <Input
                                        id={`pciFirstName${index}`}
                                        name={`pciFirstName${index}`}
                                        type="text"
                                        value={item.firstName}
                                        onChange={(e)=>{
                                            const updatedPastCustomers = [...customerInfo.pastCustomers];
                                            updatedPastCustomers[index].firstName = e.target.value;
                                            setCustomerInfo({ ...customerInfo, pastCustomers: updatedPastCustomers });
                                        }}
                                    />
                                </div>
                                <div className="col-6 g-col-6">
                                    <Input
                                        id={`pciLastName${index}`}
                                        name={`pciLastName${index}`}
                                        type="text"
                                        value={item.lastName}
                                        onChange={(e)=>{
                                            const updatedPastCustomers = [...customerInfo.pastCustomers];
                                            updatedPastCustomers[index].lastName = e.target.value;
                                            setCustomerInfo({ ...customerInfo, pastCustomers: updatedPastCustomers });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 m-2'>
                            <LabelDecoration label={"フリガナ"} />
                            <div className="row grid d-flex justify-content-center mt-1">
                                <div className="col-6 g-col-6">
                                    <Input
                                        id={`pcifuFirstname${index}`}
                                        name={`pcifuFirstname${index}`}
                                        type="text"
                                        value={item.furiganaFirstName}
                                        onChange={(e)=>{
                                            const updatedPastCustomers = [...customerInfo.pastCustomers];
                                            updatedPastCustomers[index].furiganaFirstName = e.target.value;
                                            setCustomerInfo({ ...customerInfo, pastCustomers: updatedPastCustomers });
                                        }}
                                    />
                                </div>
                                <div className="col-6 g-col-6">
                                    <Input
                                        id={`pcifuLastName${index}`}
                                        name={`pcifuLastName${index}`}
                                        type="text"
                                        value={item.furiganaLastName}
                                        onChange={(e)=>{
                                            const updatedPastCustomers = [...customerInfo.pastCustomers];
                                            updatedPastCustomers[index].furiganaLastName = e.target.value;
                                            setCustomerInfo({ ...customerInfo, pastCustomers: updatedPastCustomers });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <CustomizeAddButton id='customerInfoAddBtn' text='過去の契約者情報の追加' onClick={addCustomerInfo} />
                {/* ----------------------- customer add end  --------------------- */}
                <div className='row grid d-flex justify-content-center'>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"郵便番号"} />
                        <div className="mt-1">
                            <Input
                                id="zipCode"
                                name="zipCode"
                                type="text"
                                value={customerInfo.zipCode}
                                onChange={(e)=>{setCustomerInfo({...customerInfo, zipCode: e.target.value})}}
                            />
                        </div>
                    </div>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"住所"} />
                        <div className="mt-1">
                            <Input
                                id="address"
                                name="address"
                                type="text"
                                value={customerInfo.address}
                                onChange={(e)=>{setCustomerInfo({...customerInfo, address: e.target.value})}}
                            />
                        </div>
                    </div>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"GoogleMap"} />
                        <div className="mt-1">
                            <Input
                                id="googleMapCode"
                                name="googleMapCode"
                                type="text"
                                value={customerInfo.googleMapCode}
                                onChange={(e)=>{setCustomerInfo({...customerInfo, googleMapCode: e.target.value})}}
                            />
                        </div>
                    </div>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"電話番号"} />
                        <div className="mt-1">
                            <Input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="number"
                                value={customerInfo.phoneNumber}
                                onChange={e => setCustomerInfo({...customerInfo, phoneNumber: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"契約日"} />
                        <div className="mt-1">
                            <DatePicker
                                dateFormat="yyyy/MM/dd"
                                selected={customerInfo.contractDate}
                                onChange={(date) => setCustomerInfo({...customerInfo, contractDate: date})}
                            />
                        </div>
                    </div>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"工事日"} />
                        <div className="mt-1">
                            <DatePicker
                                dateFormat="yyyy/MM/dd"
                                selected={customerInfo.constructionDate}
                                onChange={(date) => setCustomerInfo({...customerInfo, constructionDate: date})}
                            />
                        </div>
                    </div>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"カテゴリ"} />
                        <div className="mt-1">
                            <Input
                                id="category"
                                className='c_select'
                                name="category"
                                type="select"
                                value={customerInfo.category}
                                onChange={(e)=>{setCustomerInfo({...customerInfo, category: e.target.value})}}
                            >
                                <option value={'工事'}>工事</option>
                                <option value={'メンテナンス'}>メンテナンス</option>
                                <option value={'点検のみ'}>点検のみ</option>
                                <option value={'未訪問'}>未訪問</option>
                                <option value={'自社工事'}>自社工事</option>
                                <option value={'自社工事＋メンテナンス'}>自社工事＋メンテナンス</option>
                                <option value={'他社入替'}>他社入替</option>
                            </Input>
                        </div>
                    </div>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"販売種別"} />
                        <div className="mt-1">
                            <Input
                                id="salesType"
                                name="salesType"
                                type="select"
                                className='c_select'
                                value={customerInfo.salesType}
                                onChange={(e)=>{setCustomerInfo({...customerInfo, salesType: e.target.value})}}
                            >
                                <option value={''}></option>
                                <option value={'エコキュート単体'}>エコキュート単体</option>
                                <option value={'電化セット / 太陽光発電'}>電化セット / 太陽光発電</option>
                                <option value={'電化セット'}>電化セット</option>
                                <option value={'太陽光'}>太陽光</option>
                                <option value={'IH'}>IH</option>
                                <option value={'エコキュート単体 / 太陽光'}>エコキュート単体 / 太陽光</option>
                            </Input>
                        </div>
                    </div>
                </div>
                { customerInfo.ECFAddedDate.map((item, index)=>(
                    <div key={`updateAt${index}`} className='row grid d-flex justify-content-center'>
                        <div className='col-12 m-2 position-relative'>
                            { customerInfo.ECFAddedDate.length > 1 &&
                                <CustomizeCloseBtn 
                                    id={`pastContractDate${index}`} 
                                    text={`過去の契約日 (${index+1})の削除`} 
                                    index={index}
                                    onClick={(e)=>{removePastContractDate(e)}}
                                />
                            }
                            <LabelDecoration label={`エコキュートの型式 (${index+1})`} />
                            <div className="mt-1">
                                { !ecocuteType[index]?.type ? 
                                    <Input
                                        id={`ecocuteModel${index}`}
                                        name={`ecocuteModel${index}`}
                                        type="select"
                                        className='c_select'
                                        value={item.ecocuteModel}
                                        onChange={(e)=>{
                                            const updatedECFAddedDate = [...customerInfo.ECFAddedDate];
                                            updatedECFAddedDate[index].ecocuteModel = e.target.value;
                                            setCustomerInfo({ ...customerInfo, ECFAddedDate: updatedECFAddedDate });
                                        }}
                                    >
                                        <option value={''}></option>
                                        <option value = {'EQ37HFV'}>EQ37HFV</option>
                                        <option value = {'EQ46HFV'}>EQ46HFV</option>
                                        <option value = {'EQ37JFV'}>EQ37JFV</option>
                                        <option value = {'EQ46JFV'}>EQ46JFV</option>
                                        <option value = {'EQ37KFV'}>EQ37KFV</option>
                                        <option value = {'EQ46KFV'}>EQ46KFV</option>
                                        <option value = {'EQ37LFV'}>EQ37LFV</option>
                                        <option value = {'EQ46LFV'}>EQ46LFV</option>
                                    </Input>:
                                    <Input
                                        id={`ecocuteModel_${index}`}
                                        name={`ecocuteModel_${index}`}
                                        type="text"
                                        value={item.ecocuteModel}
                                        onChange={e => {
                                            const updatedECFAddedDate = [...customerInfo.ECFAddedDate];
                                            updatedECFAddedDate[index].ecocuteModel = e.target.value;
                                            setCustomerInfo({ ...customerInfo, ECFAddedDate: updatedECFAddedDate });
                                        }}
                                    />
                                }
                            </div>
                            <div className='d-flex flex-row mt-2'>
                                <Label check>手動で入力する</Label>&nbsp;&nbsp;
                                <FormGroup switch>
                                    <Input
                                        type="switch"
                                        checked={ecocuteType[index]?.type || false}
                                        onChange={(e) => {
                                            const eType = e.target.checked;
                                            const temp = [...ecocuteType]; // Create a shallow copy of the state array
                                            temp[index] = { type: eType };
                                            const updatedECFAddedDate = [...customerInfo.ECFAddedDate];
                                            updatedECFAddedDate[index].ecocuteModel = "";
                                            setCustomerInfo({ ...customerInfo, ECFAddedDate: updatedECFAddedDate });
                                            setEcocuteType(temp);
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='col-12 m-2'>
                            <div>更新日</div>
                            <div className="mt-1">
                                <DatePicker
                                    id={`updateAt${index}`}
                                    name={`updateAt${index}`}
                                    dateFormat="yyyy/MM/dd"
                                    selected={item.updateAt}
                                    onChange={(date) => {
                                        const newUpdateDates = [...customerInfo.ECFAddedDate];
                                        newUpdateDates[index].updateAt = date;
                                        setCustomerInfo({ ...customerInfo, ECFAddedDate: newUpdateDates });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                <CustomizeAddButton id='pastContractDate' text='過去の契約日' onClick={addPastContractDate} />
                {/* ----------------------------------upload pdf------------------------------ */}
                    
                <div className='row grid d-flex justify-content-center'>
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"添付PDF"} />
                        <div className="mt-1">
                            <Button outline style={{ fontFamily: 'Hiragino Kaku Gothic ProN' }} onClick={AddPDFFile}>
                                ファイルの選択
                            </Button>
                        </div>
                    </div>
                    <input type="file" id="fileupload" ref={pdfFilesSelect} className="d-none" onChange={PDFInputChange} multiple accept=".pdf,application/pdf" />
                    {/* ----------------------------------------- */}
                    { customerInfo.pdfFiles.length > 0 &&
                        <Table bordered hover responsive size="sm" striped>
                            <thead>
                                <tr className='text-center'>
                                    <th>#</th>
                                    <th>ファイル名</th>
                                    <th>サイズ</th>
                                    <th>アクション</th>
                                </tr>
                            </thead>
                            <tbody>
                                { customerInfo.pdfFiles.map((data, index)=>{
                                    const { id, filename, filetype, file, filesize } = data;
                                    return (
                                        <tr key={id} className='align-middle'>
                                            <td scope="row" className="w-10">{index+1}</td>
                                            <td className="w-50" style={{minWidth: '89px'}}>{filename}</td>
                                            <td className="w-20 text-center" style={{minWidth: '70px'}}>{filesize}</td>
                                            <td className="w-20 text-center" style={{minWidth: '89px'}}>
                                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => openPDFPreView(file, filename)}>
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                </button>&nbsp;
                                                <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => DeleteSelectedPDF(id, filename)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                    {/* -------------------------------end upload pdf---------------------------- */}
                    {/* ------------------------------- upload image ---------------------------- */}
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"添付画像"} />
                        <div className='row'>
                            { customerInfo.imageFiles.map((data, index)=>{
                                const { id, filename, filetype, file, filesize } = data;
                                return (
                                    <div key={id} className="col-6 col-sm-6 col-md-4 py-1 mh-100 position-relative">
                                        <CustomizeCloseBtn 
                                            id={`pastContractorInformation${index}`} 
                                            text={`過去の契約者情報 (${index+1})の削除`} 
                                            index={index}
                                            onClick={() => DeleteSelectedImage(id, index)}
                                        />
                                        <Card
                                            body
                                            className="text-center justify-content-center h-100"
                                            style={{ padding: 0 }}
                                        >
                                            { !!file &&
                                                <img
                                                    alt="Card cap"
                                                    src={URL.createObjectURL(file)}
                                                    width="100%"
                                                />
                                            }
                                        </Card>
                                    </div>
                                )
                            })}
                            
                            <div key={"defaultImagebutton"} className="col-6 col-sm-6 col-md-4 py-1 mh-100">
                                <Card
                                    body
                                    className="text-center justify-content-center h-100"
                                    style={{ cursor: 'pointer' }}
                                    onClick={AddImageFile}
                                >
                                    <div className='fs-3 px-3 py-1'>
                                        <FontAwesomeIcon icon={faAdd} />
                                    </div>
                                    ファイルの選択
                                </Card>
                            </div>
                        </div>
                    </div>
                    <input type="file" id="fileupload" ref={imageFilesSelect} className="d-none" onChange={ImageInputChange} multiple accept="image/*" />
                    {/* ------------------------ end upload image --------------------- */}
                    <div className='col-12 m-2'>
                        <LabelDecoration label={"備考"} />
                        <div className="mt-1">
                            <Input
                                id="note"
                                name="note"
                                type="textarea"
                                value={customerInfo.note}
                                onChange={(e)=>{setCustomerInfo({...customerInfo, note: e.target.value})}}
                            />
                        </div>
                    </div>
                    <div className='col-12 m-2'>
                        <div className="mt-1 mb-3 d-grid">
                            <Button color="primary" onClick={saveData}>
                                保存する
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
            <ToastContainer />
            <PDFViewer 
                open={preView.open} 
                title={preView.title}
                pdfFile={preView.pdfFile}
                handleClose={closePrePDFPreView} 
            />
        </DefaultTemplate>
    );
};

export default AddNewCustomerPage;