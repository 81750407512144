import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, FormGroup, Input, InputGroup, Label } from 'reactstrap';

export type LoginFormValues = {
    email: string;
    password: string;
};

export type LoginFormHelpers = FormikHelpers<LoginFormValues>;

export interface LoginFormProps {
    initialValues: LoginFormValues;
    onSubmit: (values: LoginFormValues, helpers: LoginFormHelpers) => void;
}

const LoginForm: React.FC<LoginFormProps> = props => {
    const [ isShow, setIsShow ] = useState(false);
    const handleEyeButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault(); // Prevent form submission
        // Handle eye button click logic here
        setIsShow(!isShow);
    };

    return (
        <Formik initialValues={props.initialValues} onSubmit={props.onSubmit}>
            {form => (
                <Form onSubmit={form.handleSubmit}>
                    <FormGroup>
                        <Label for="email">メールアドレス</Label>
                        <Input
                            id="email"
                            type="email"
                            name="email"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            placeholder="name@example.com"
                            value={form.values.email}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">パスワード（半角英数字6文字以上）</Label>
                        <InputGroup>
                            <Input
                                id="password"
                                type={isShow?'text':"password"}
                                name="password"
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                placeholder="Password"
                                value={form.values.password}
                            />
                            <Button onClick={handleEyeButtonClick}>
                                { isShow?
                                    <FontAwesomeIcon icon={faEyeSlash} />:
                                    <FontAwesomeIcon icon={faEye} />
                                }
                            </Button>
                        </InputGroup>
                    </FormGroup>
                    <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                        <Button type="submit" color="primary" block>
                            ログイン
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
