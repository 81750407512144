import React from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'hooks';
import { Link } from 'react-router-dom';
import { NavbarToggler, Navbar, NavbarBrand } from 'reactstrap';
import { uiActions } from 'store/uiSlice';

export interface DetailTopnavProps {}

const DetailTopnav: React.FC<DetailTopnavProps> = () => {
    const dispatch = useAppDispatch();

    const toggleSidenav = (): void => {
        dispatch(uiActions.toggleSetting('sidebarToggled'));
    };

    return (
        <Navbar color="faded" light>
            <NavbarBrand tag={Link} to="/">
                <img src={process.env.REACT_APP_LOGO} style={{ marginLeft: 15 }} width={"40px"} alt='logo' />
            </NavbarBrand>
            <NavbarToggler className="ms-auto ms-md-0 me-3 me-lg-4" onClick={toggleSidenav}>
                <FontAwesomeIcon icon={faBars} />
            </NavbarToggler>
        </Navbar>
    );
};

export default DetailTopnav;
