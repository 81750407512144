import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export interface DeleteCustomerProps {
    open: boolean;
    title: string;
    close: ()=>void;
    agree: ()=>void;
}

const DeleteCustomer: React.FC<DeleteCustomerProps> = ({
    open, title, close, agree
}) => {
    return (
        <Modal isOpen={open} toggle={close} 
            className={"modal-fullscreen-sm-down modal-dialog-centered"}>
            <ModalHeader>{title}</ModalHeader>
            <ModalFooter>
                <Button color="danger" onClick={agree}>削除する</Button>{' '}
                <Button color="secondary" onClick={close}>閉じる</Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteCustomer;