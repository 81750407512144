import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import { Container, Table, Card, Button } from 'reactstrap';
import { faAdd, faFilePdf, faTrash, faAngleRight, faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomerManagementHelper } from 'helper';
import { DetailTemplate } from 'components/templates';
import { 
    LabelwithButtonDecoration, LabelDecoration, CustomerEditModel, DetailItem, 
    DetailPDFPreview, CustomizeCloseBtn, ContractDocumentModel, DeleteModal,
    DeleteCustomer, CustomizeAddButton, PDFRender
} from "components/organisms";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useAuth } from 'hooks';

export interface CustomerDetailProps {};

interface PastCustomerInfoProps {
    firstName: string;
    lastName: string;
    furiganaFirstName: string;
    furiganaLastName: string;
}

interface ECFAddedDateProps {
    ecocuteModel: string;
    updateAt: Date | null; // Allow null
}

interface FileProps {
    id: string;
    filePath: string;
    originalName: string;
    createdAt: Date | null; // Allow null
}

interface DeleteDataprops {
    open: boolean;
    title: string;
    content: string;
    type: string;
    data: FileProps|ContractDocumentProps|null;
}

interface DeleteCustomerProps {
    open: boolean;
    title: string;
}

interface RowsProps {
    id:string;
    firstName: string;
    lastName: string;
    furiganaFirstName: string;
    furiganaLastName: string;
    pastCustomers: Array<PastCustomerInfoProps>;
    address: string;
    zipCode: string;
    googleMapCode: string;
    phoneNumber: string;
    contractDate: Date | null; // Allow null
    constructionDate: Date | null; // Allow null
    category: string;
    salesType: string;
    ECFAddedDate: Array<ECFAddedDateProps>;
    pdfFiles: Array<FileProps>;
    imageFiles: Array<FileProps>;
    estimageFiles: Array<ContractDocumentProps>;
    invoiceFiles: Array<ContractDocumentProps>;
    note: string;
    plusCode: boolean;
    bookmark: boolean;
    respectType: boolean;
}

interface EditPastCustomer {
    edit: boolean;
}

interface EditModeProps {
    customerName: boolean;
    pastCustomers: Array<EditPastCustomer>;
    address: boolean;
    zipCode: boolean;
    googleMapCode: boolean;
    phoneNumber: boolean;
    contractDate: boolean;
    constructionDate: boolean;
    category: boolean;
    salesType: boolean;
    ECFAddedDate: boolean;
    note: boolean;
}


interface PreViewProps {
    open: boolean;
    title: string;
    path: string;
}

interface ContractDocumentProps {
    open: boolean;
    id: string;
    detailId: string|undefined;
    userId: string;
    title: string;
    fullName: string;
    address: string;
    zipCode: string;
    createAt: Date|string|null;
    productRecord: Array<ProductRecordProps>;
    depositAccount: Array<DepositAccountProps>;
    paymentDeadline: string;
    remarks: string;
    electronicSeal: ElectronicSealProps|null;
    respectType: boolean;
}

interface ProductRecordProps {
    id: string;
    productName: string;
    quantity: number;
    amount: number;
    inputType: boolean;
}

interface DepositAccountProps {
    id: string;
    bankName: string;
    branchName: string;
    depositSubject: string;
    accountNumber: string;
    accountHolder: string;
}

interface ElectronicSealProps {
    changedfile: string;
}

const CustomerDetail: React.FC<CustomerDetailProps> = () => {
    const auth = useAuth();
    const { id } = useParams<{ id: string }>(); // Ensure id is always a string
    const [ flag, setFlag ] = useState<Boolean>(false);
    const [ row, setRow ] = useState<RowsProps>({
        id:"",
        firstName: "",
        lastName: "",
        furiganaFirstName: "",
        furiganaLastName: "",
        pastCustomers: [],
        address: "",
        zipCode: "",
        googleMapCode: "",
        phoneNumber: "",
        contractDate: null,
        constructionDate: null,
        category: "",
        salesType: "",
        ECFAddedDate: [],
        pdfFiles: [],
        imageFiles: [],
        estimageFiles: [],
        invoiceFiles: [],
        note: "",
        plusCode: false,
        bookmark: false,
        respectType: false,
    });

    const [ editMode, setEditMode ] = useState<EditModeProps>({
        customerName: false,
        pastCustomers: [],
        address: false,
        zipCode: false,
        googleMapCode: false,
        phoneNumber: false,
        contractDate: false,
        constructionDate: false,
        category: false,
        salesType: false,
        ECFAddedDate: false,
        note: false,
    });
    
    const [ isOpenEditModel, setIsOpenEditModel ] = useState<boolean>(false);
    useEffect(()=>{
        if (id) {
            CustomerManagementHelper.getRow(id)
            .then((res: any)=>{
                const { row } = res.data;
                setFlag(!flag);
                const tempEditPastCustomer: Array<EditPastCustomer> = [];
                for (let i = 0; i < row.pastCustomers.length; i++) {
                    tempEditPastCustomer.push({edit: false})
                }
                setEditMode({...editMode, pastCustomers: tempEditPastCustomer});
                setRow(row);
            })
            .catch((err: any)=>{
                window.location.href = '/logout';
            })
        }
    }, []);

    const redirectCustomerList = () =>{
        window.location.href= '/'
    }

    const editPastCustomer = (id: number) => {
        const tempEditPastCustomer: Array<EditPastCustomer> = [];
        for (let i = 0; i < row.pastCustomers.length; i++) {
            if( id === i )tempEditPastCustomer.push({edit: true})
            else tempEditPastCustomer.push({edit: false})
        }
        setFlag(!flag);
        setIsOpenEditModel(true);
        setEditMode({...editMode, pastCustomers: tempEditPastCustomer});
    }
    // -----------
    const closeEditModel = () => {
        setIsOpenEditModel(false);
        const tempEditPastCustomer: Array<EditPastCustomer> = [];
        for (let i = 0; i < row.pastCustomers.length; i++) {
            tempEditPastCustomer.push({edit: false})
        }
        setEditMode({
            customerName: false,
            pastCustomers: tempEditPastCustomer,
            address: false,
            zipCode: false,
            googleMapCode: false,
            phoneNumber: false,
            contractDate: false,
            constructionDate: false,
            category: false,
            salesType: false,
            ECFAddedDate: false,
            note: false,
        });
    }

    const saveEditModel = (e: RowsProps) => {
        const validationData = checkValidation(e);
        if(validationData){
            CustomerManagementHelper.updateRowOne(e)
                .then((res:any)=>{
                    setRow(e);
                })
                .catch((err: any)=>{
                    window.location.href = '/logout';
                })
                .finally(()=>{
                    closeEditModel();
                })
        }
    }

    const checkValidation = (customerInfo: RowsProps): boolean  => {
        if(!customerInfo.firstName||!customerInfo.lastName){
            errorToast('名前');
            return false;
        }
        if(!customerInfo.furiganaFirstName||!customerInfo.furiganaLastName){
            errorToast('フリガナ');
            return false;
        }
        if(!customerInfo.zipCode){
            errorToast('郵便番号');
            return false;
        }
        if(!customerInfo.address){
            errorToast('住所');
            return false;
        }
        if(!customerInfo.googleMapCode){
            errorToast('GoogleMap');
            return false;
        }
        if(!customerInfo.phoneNumber){
            errorToast('電話番号');
            return false;
        }
        if(!customerInfo.salesType){
            errorToast('販売種別');
            return false;
        }
        if(!checkObjectArray(customerInfo.ECFAddedDate)){
            errorToast('エコキュートの型式');
            return false;
        }
        return true;
    }

    function checkObjectArray(array: ECFAddedDateProps[]): boolean {
        if( array.length > 1 ){
            for (let obj of array) {
                if (!obj.ecocuteModel || !obj.updateAt) {
                    return false;
                }
            }
        } else {
            if((!!array[0].ecocuteModel && !!array[0].updateAt) || (!array[0].ecocuteModel && !array[0].updateAt)) return true;
            else return false;
        }
        return true;
    }

    const errorToast = (msg: string) => {
        toast.error(`「${msg}」を確認してください。`, {
            position: "top-center",
            autoClose: 1000,
        });
    }

    const convertTime = (d: Date|string|null) => {
        if(!d) return "";
        const date = new Date(d);

        return date.toLocaleString('ja-JP', {
            year: 'numeric',
            month: 'long', // Full month name
            day: 'numeric',
            timeZone: 'Asia/Tokyo' // Specify the timezone if needed
        });
    };
    // pdf file management 
    const pdfFilesSelect = useRef<HTMLInputElement>(null);
    // pdf preview
    const [ preView, setPreView ] = useState<PreViewProps>({
        open: false, 
        title: "",
        path: ""
    });

    const openPDFPreView = (path: string, filename: string) => {
        if (!!path) {
            setPreView({
              open: true,
              title: filename,
              path: "pdf/"+path
            });
        }
    }

    const closePDFPreview = () => {
        setPreView({
            open: false, 
            title: "",
            path: ""
        });
    }

    const PDFInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // --For Multiple File Input
        if (e.target.files) {
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];
                const reader = new FileReader();

                reader.onloadend = () => {
                    if (file) {
                        CustomerManagementHelper.uploadFile(reader.result as string, file.name, "pdf")
                        .then((res:any)=>{
                            const data = res.data;
                            const temp = { ...row, pdfFiles: [ ...row.pdfFiles, data ] };
                            saveEditModel(temp)
                            // setRow(tempRow);
                            toast.success(`「${file.name}」が追加されました。`, {
                                position: "top-center",
                                autoClose: 600,
                            });
                        })
                        .catch((err: any)=>{
                            window.location.href = '/logout';
                        })
                    }
                }
                if (file) {
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    const DeleteSelectedPDF = (id: string, originalName: string, filePath: string) => {
        const result = row.pdfFiles.filter((data) => data.id !== id);
        const tempRow = { ...row, pdfFiles: result }
        CustomerManagementHelper.deleteFile(tempRow, filePath, 'pdf')
        .then((res:any)=>{
            setRow(tempRow);
            toast.success(`「${originalName}」を削除しました`, {
                position: "top-center",
                autoClose: 600,
            });
        })
        .catch((err: any)=>{
            window.location.href = '/logout';
        })
    };
    
    const AddPDFFile = () => {
        if (pdfFilesSelect.current) {
            pdfFilesSelect.current.click();
        }
    }
    // ---
    const DeleteSelectedImage = (id: string, originalName: string, filePath: string) => {
        const result = row.imageFiles.filter((data) => data.id !== id);
        const tempRow = { ...row, imageFiles: result }
        CustomerManagementHelper.deleteFile(tempRow, filePath, 'image')
            .then((res:any)=>{
                setRow(tempRow);
                toast.success(`「${originalName}」を削除しました`, {
                    position: "top-center",
                    autoClose: 600,
                });
            })
            .catch((err: any)=>{
                window.location.href = '/logout';
            })
    };
    
    const imageFilesSelect = useRef<HTMLInputElement>(null);
    const AddImageFile = () => {
        if (imageFilesSelect.current) {
            imageFilesSelect.current.click();
        }
    }
    const ImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // --For Multiple File Input
        if (e.target.files) {
            // let temp: FileProps[] = [];
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (file) {
                        CustomerManagementHelper.uploadFile(reader.result as string, file.name, "image")
                        .then((res:any)=>{
                            const data = res.data;
                            const temp = { ...row, imageFiles: [ ...row.imageFiles, data ] };
                            saveEditModel(temp)
                            // setRow(tempRow);
                            toast.success(`「${file.name}」が追加されました。`, {
                                position: "top-center",
                                autoClose: 600,
                            });
                        })
                        .catch((err: any)=>{
                            window.location.href = '/logout';
                        })
                    }
                }
                if (file) {
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    const [ contractDocument, setContractDocument] = useState<ContractDocumentProps>({
        open: false,
        id: "",
        detailId: "",
        userId: "",
        title: "",
        fullName: "",
        address: "",
        zipCode: "",
        createAt: null,
        productRecord: [],
        depositAccount: [],
        paymentDeadline: "お支払い期日：請求書の受領から14日以内",
        remarks: "",
        electronicSeal: null,
        respectType: false,
    })

    const createContractDocument = (type: string) => {
        const userId: string = auth.user.id;
        setContractDocument({
            open: true,
            id: "new", 
            detailId: id, 
            userId,
            title: (type === "invoice") ? "請求書作成" : "見積書作成",
            fullName: row.firstName+row.lastName,
            address: row.address,
            zipCode: row.zipCode,
            createAt: null,
            productRecord: [],
            depositAccount: [],
            paymentDeadline: "お支払い期日：請求書の受領から14日以内",
            remarks: "",
            electronicSeal: null,
            respectType: false
        });
    }

    const editContractDocument = (item: ContractDocumentProps) => {
        setContractDocument({...item, open: true})
    }

    const [ deleteData, setDeleteData ] =useState<DeleteDataprops>({
        open: false,
        title: "",
        content: "",
        type: "",
        data: null
    });

    const downloadDocument = (path: string, filename: string) => {
        console.log(process.env.REACT_APP_File_URI+path)
        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = process.env.REACT_APP_File_URI+"pdf/"+path;
        a.download = filename; // Set the filename for the download

        // Append the anchor element to the DOM
        document.body.appendChild(a);

        // Trigger the click event on the anchor element
        a.click();

        // Remove the anchor element from the DOM
        document.body.removeChild(a);
    }

    const deleteContractDocument = (item: ContractDocumentProps) => {
        setDeleteData({
            open: true,
            title: (item.title==='請求書作成')?`この「請求書」ファイルを削除してもよろしいですか？`:`この「見積書」ファイルを削除してもよろしいですか？`,
            content: `${convertTime(item.createAt)}更新：${item.id}`,
            type: "contract",
            data: item
        });
    }

    const handleDeleteCancel = () => {
        setDeleteData({
            open: false,
            title: "",
            content: "",
            type: "",
            data: null
        });
    }

    const handleDeletePDF = () => {
        if(!!deleteData.data){
            if(deleteData.type ==='contract'){
                const data = deleteData.data as ContractDocumentProps;
                CustomerManagementHelper.deleteContractDocument(data)
                    .then((res:any)=>{
                        const { row } = res.data;
                        setRow(row);
                        toast.success((data.title==='請求書作成')?`1つの「請求書」が削除されました。`:`1つの「見積書」が削除されました。`, {
                            position: "top-center",
                            autoClose: 600,
                        });
                    })
                    .catch((err: any)=>{
                        window.location.href = '/logout';
                    })
                    .finally(()=>{
                        handleDeleteCancel();
                    })
            }

        }
        
    }

    const closeContractDocumentModel = () => {
        setContractDocument({
            open: false,
            id: "",
            detailId: id,
            userId: "",
            title: "",
            fullName: "",
            address: "",
            zipCode: "",
            createAt: null,
            productRecord: [],
            depositAccount: [],
            paymentDeadline: "お支払い期日：請求書の受領から14日以内",
            remarks: "",
            electronicSeal: null,
            respectType: false
        })
    }

    const saveContractDocumentModel = (data: any) => {
        CustomerManagementHelper.contractDocumentSave(data)
            .then((res:any)=>{
                const { row } = res.data;
                setFlag(!flag);
                const tempEditPastCustomer: Array<EditPastCustomer> = [];
                for (let i = 0; i < row.pastCustomers.length; i++) {
                    tempEditPastCustomer.push({edit: false})
                }
                setEditMode({...editMode, pastCustomers: tempEditPastCustomer});
                setRow(row);
            })
            .catch((err: any)=>{
                window.location.href = '/logout';
            })
            .finally(()=>{
                closeContractDocumentModel();
            })
    }

    const [ deleteCustomerData, setDeleteCustomerData ] =useState<DeleteCustomerProps>({
        open: false,
        title: "",
    });

    const handleDeleteCustomer = () =>{
        setDeleteCustomerData({
            open: true,
            title: '顧客情報を削除してもよろしいですか？',
        });
    }

    const handleDeleteCustomerCancel = () => {
        setDeleteCustomerData({
            open: false,
            title: '顧客情報を削除してもよろしいですか？',
        });
    }

    const handleDeleteCustomerOK = () => {
        if (id) {
            CustomerManagementHelper.deleteRow(id)
            .then((res: any)=>{
                redirectCustomerList();
            })
            .catch((err: any)=>{
                window.location.href = '/logout';
            })
        }
    }

    const removeCustomerInfo = (index: number) =>{
        const updatedPastCustomers = [...row.pastCustomers];
        updatedPastCustomers.splice(index, 1);
        setRow({ ...row, pastCustomers: updatedPastCustomers });
        // edit mode
        const ePastCustomers = [...editMode.pastCustomers];
        ePastCustomers.splice(index, 1);
        setEditMode({ ...editMode, pastCustomers: ePastCustomers });
    }

    const addCustomerInfo = () => {
        // Create a new PastCustomerInfoProps object
        const newPastCustomerInfo: PastCustomerInfoProps = {
            firstName: "",
            lastName: "",
            furiganaFirstName: "",
            furiganaLastName: "",
        };
        // Create a copy of the existing pastCustomers array
        const updatedPastCustomers = [...row.pastCustomers];
        // Add the new PastCustomerInfoProps object to the copied array
        updatedPastCustomers.push(newPastCustomerInfo);
        // Update the customerInfo state with the copied array
        setRow({ ...row, pastCustomers: updatedPastCustomers });
        // edit mode
        const tempEditPastCustomer: Array<EditPastCustomer> = [];
        for (let i = 0; i < row.pastCustomers.length; i++) {
            tempEditPastCustomer.push({edit: false})
        }
        tempEditPastCustomer.push({edit: true})
        setFlag(!flag);
        setIsOpenEditModel(true);
        setEditMode({...editMode, pastCustomers: tempEditPastCustomer});
    }

    const removePastContractDate = (index: number) => {
        const updatedPastContractDate = [...row.ECFAddedDate];
        updatedPastContractDate.splice(index, 1);
        setRow({
            ...row,
            ECFAddedDate: updatedPastContractDate,
        });
    }
    
    const addECFAddedDate = () => {
        // Create a new UpdateAtProps object
        const newPastCustomerInfo: ECFAddedDateProps = {
            ecocuteModel:'1',
            updateAt: new Date(),
        };

        // Create a copy of the existing ECFAddedDate array
        const updatedPastContractDate = [...row.ECFAddedDate];

        // Add the new PastCustomerInfoProps object to the copied array
        updatedPastContractDate.push(newPastCustomerInfo);

        // Update the row state with the copied array
        setRow({ ...row, ECFAddedDate: updatedPastContractDate });
        setIsOpenEditModel(true);
        setEditMode({...editMode, ECFAddedDate: true});
    }

    // Sorting function
    const sortECFByUpdateAt = (a: ECFAddedDateProps, b: ECFAddedDateProps): number => {
        const dateA: Date | null = a.updateAt ? new Date(a.updateAt) : null;
        const dateB: Date | null = b.updateAt ? new Date(b.updateAt) : null;
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;
        
        return dateB.getTime() - dateA.getTime();
    };

    // Sorting function
    const sortDocumentByUpdateAt = (a: ContractDocumentProps, b: ContractDocumentProps): number => {
        const dateA: Date | null = a.createAt ? new Date(a.createAt) : null;
        const dateB: Date | null = b.createAt ? new Date(b.createAt) : null;
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;
        
        return dateB.getTime() - dateA.getTime();
    };
    
    // Sorting function
    const sortUploadedFileByUpdateAt = (a: FileProps, b: FileProps): number => {
        const dateA: Date | null = a.createdAt ? new Date(a.createdAt) : null;
        const dateB: Date | null = b.createdAt ? new Date(b.createdAt) : null;
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;
        
        return dateB.getTime() - dateA.getTime();
    };

    return (
        <DetailTemplate>
            <Container className="mb-5">
                <div>
                    <div className="d-flex justify-content-center font-weight-light" style={{ fontSize: '14px'}}>{row.furiganaFirstName}{row.furiganaLastName}</div>
                    <div className="d-flex justify-content-center fs-4">{row.firstName}{row.lastName}</div>
                </div>
                
                <DetailItem 
                    label={`氏名`}
                    text={`${row.firstName + row.lastName} (${row.furiganaFirstName + row.furiganaLastName})`}
                    editClick={()=>{
                        setIsOpenEditModel(true);
                        setEditMode({...editMode, customerName: true});
                    }}
                />

                { row.pastCustomers.map((item:PastCustomerInfoProps, index)=>(
                    <div key={`pastCustomers${index}`}>
                        { row.pastCustomers.length > 1 &&
                            <CustomizeCloseBtn 
                                id={`pastContractorInformation${index}`} 
                                text={`過去の契約者情報 (${index+1})の削除`} 
                                index={index}
                                onClick={(e)=>{removeCustomerInfo(e)}}
                            />
                        }
                        <DetailItem 
                            key={`pastCustomers${index}`}
                            label={(row.pastCustomers.length===1)?"過去の契約者情報":`過去の契約者情報 (${index+1})`}
                            text={(item.firstName===""&&item.lastName===""&&item.furiganaFirstName===""&&item.furiganaLastName==="")?" ":`${item.firstName}${item.lastName} (${item.furiganaFirstName}${item.furiganaLastName})`}
                            editClick={()=>editPastCustomer(index)}
                        />
                    </div>
                ))}

                <CustomizeAddButton id='customerInfoAddBtn' text='過去の契約者情報の追加' onClick={addCustomerInfo} />
                
                <DetailItem 
                    label={`郵便番号`}
                    text={row.zipCode}
                    editClick={()=>{
                        setIsOpenEditModel(true);
                        setEditMode({...editMode, zipCode: true});
                    }}
                />
                
                <DetailItem 
                    label={`住所`}
                    text={row.address}
                    editClick={()=>{
                        setIsOpenEditModel(true);
                        setEditMode({...editMode, address: true});
                    }}
                />
                <div className='row grid d-flex justify-content-center'>
                    <div className='col-12 m-2'>
                        <LabelwithButtonDecoration label={`GoogleMap`} btnLabel={"編集"} handleEdit={()=>{
                            setIsOpenEditModel(true);
                            setEditMode({...editMode, googleMapCode: true});
                        }} />
                        <div className="mt-2">
                        <iframe src={`https://plus.codes/${row.googleMapCode}`} height="500" width="100%" title="Iframe Example"></iframe>
                        </div>
                    </div>
                </div>
                <DetailItem 
                    label={`電話番号`}
                    text={row.phoneNumber}
                    editClick={()=>{
                        setIsOpenEditModel(true);
                        setEditMode({...editMode, phoneNumber: true});
                    }}
                />
                <DetailItem 
                    label={`契約日`}
                    text={convertTime(row.contractDate)}
                    editClick={()=>{
                        setIsOpenEditModel(true);
                        setEditMode({...editMode, contractDate: true});
                    }}
                />
                <DetailItem 
                    label={`工事日`}
                    text={convertTime(row.constructionDate)}
                    editClick={()=>{
                        setIsOpenEditModel(true);
                        setEditMode({...editMode, constructionDate: true});
                    }}
                />
                <DetailItem 
                    label={`カテゴリ`}
                    text={row.category}
                    editClick={()=>{
                        setIsOpenEditModel(true);
                        setEditMode({...editMode, category: true});
                    }}
                />
                <DetailItem 
                    label={`販売種別`}
                    text={row.salesType}
                    editClick={()=>{
                        setIsOpenEditModel(true);
                        setEditMode({...editMode, salesType: true});
                    }}
                />
                <div className='row grid d-flex justify-content-center'>
                    <div className='col-12 m-2 py-2'>
                        <LabelwithButtonDecoration label={`エコキュートの型式`} btnLabel={"編集"} handleEdit={()=>{
                            setIsOpenEditModel(true);
                            setEditMode({...editMode, ECFAddedDate: true});
                        }} />
                        <div className="mt-2">
                            { row.ECFAddedDate.sort(sortECFByUpdateAt).map((item: ECFAddedDateProps, index)=>(
                                <div key={`ECFAddedDate${index}`} className="position-relative">
                                    { row.ECFAddedDate.length > 1 &&
                                        <CustomizeCloseBtn 
                                            id={`pastContractDate${index}`} 
                                            text={`過去の契約日 (${index+1})の削除`} 
                                            index={index}
                                            onClick={(e)=>{removePastContractDate(e)}}
                                        />
                                    }
                                    {`${convertTime(item.updateAt)}更新：${item.ecocuteModel}`}
                                </div>
                            ))}
                            <CustomizeAddButton id='pastContractDate' text='過去の契約日' onClick={addECFAddedDate} />
                        </div>
                    </div>
                </div>
                {/* estimate pdf */}
                <div className='row grid d-flex justify-content-center'>
                    <div className='col-12 m-2 py-2'>
                        <LabelwithButtonDecoration label={`見積書PDF`} btnLabel={"作成"} handleEdit={()=>createContractDocument("estimate")} />
                        { row.estimageFiles.length > 0 &&
                            <Table bordered hover responsive size="sm" striped className="mt-2">
                                <thead>
                                    <tr className='text-center'>
                                        <th>請求書PDF</th>
                                        <th>アクション</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { row.estimageFiles.sort(sortDocumentByUpdateAt).map((item: ContractDocumentProps, index)=>(
                                        <tr key={`invoiceFiles${index}`}>
                                            <td className="w-30" style={{minWidth: '121px'}}>{`${convertTime(item.createAt)}更新：${item.id}`}</td>
                                            <td className="w-20 text-center" style={{minWidth: '115px'}}>
                                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => editContractDocument(item)}>
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                </button>&nbsp;
                                                <PDFDownloadLink className='btn btn-outline-primary btn-sm' document={<PDFRender contractDocument={item} />} fileName={'見積書PDF['+item.id+']'}>
                                                    {({ blob, url, loading, error }) =>
                                                        <FontAwesomeIcon icon={loading ?faSpinner: faDownload} /> 
                                                    }
                                                </PDFDownloadLink>&nbsp;
                                                <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteContractDocument(item)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        }
                    </div>
                </div>
                {/* invoice pdf */}
                <div className='row grid d-flex justify-content-center'>
                    <div className='col-12 m-2 py-2'>
                        <LabelwithButtonDecoration label={`請求書PDF`} btnLabel={"作成"} handleEdit={()=>createContractDocument("invoice")} />
                        { row.invoiceFiles.length > 0 &&
                            <Table bordered hover responsive size="sm" striped className="mt-2">
                                <thead>
                                    <tr className='text-center'>
                                        <th>請求書PDF</th>
                                        <th>アクション</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { row.invoiceFiles.sort(sortDocumentByUpdateAt).map((item: ContractDocumentProps, index)=>(
                                        <tr key={`invoiceFiles${index}`}>
                                            <td className="w-30" style={{minWidth: '121px'}}>{`${convertTime(item.createAt)}更新：${item.id}`}</td>
                                            <td className="w-20 text-center" style={{minWidth: '115px'}}>
                                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => editContractDocument(item)}>
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                </button>&nbsp;
                                                <PDFDownloadLink className='btn btn-outline-primary btn-sm' document={<PDFRender contractDocument={item} />} fileName={'請求書PDF['+item.id+']'}>
                                                    {({ blob, url, loading, error }) =>
                                                        <FontAwesomeIcon icon={loading ?faSpinner: faDownload} />
                                                    }
                                                </PDFDownloadLink>&nbsp;
                                                <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteContractDocument(item)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        }
                    </div>
                </div>
                {/* end estimate and invoice pdf */}
                <div className='row grid d-flex justify-content-center py-2'>
                    <div className='col-12 m-2'>
                        <LabelwithButtonDecoration label={`添付PDF`} btnLabel={"ファイルを選択"} handleEdit={AddPDFFile} />
                    </div>
                    <input type="file" id="pdfupload" ref={pdfFilesSelect} className="d-none" onChange={PDFInputChange} accept=".pdf,application/pdf" />
                    { row.pdfFiles.length > 0 &&
                        <Table bordered hover responsive size="sm" striped className="mt-2">
                            <thead>
                                <tr className='text-center'>
                                    <th>#</th>
                                    <th>ファイル名</th>
                                    <th>アップロード日</th>
                                    <th>アクション</th>
                                </tr>
                            </thead>
                            <tbody>
                                { row.pdfFiles.sort(sortUploadedFileByUpdateAt).map((data, index)=>{
                                    const { id, filePath, originalName, createdAt } = data;
                                    return (
                                        <tr key={id} className='align-middle'>
                                            <td scope="row" className="w-10">{index+1}</td>
                                            <td className="w-50" style={{minWidth: '89px'}}>{originalName}</td>
                                            <td className="w-30" style={{minWidth: '121px'}}>{convertTime(createdAt)}</td>
                                            <td className="w-20 text-center" style={{minWidth: '115px'}}>
                                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => openPDFPreView(filePath, originalName)}>
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                </button>&nbsp;
                                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => downloadDocument(filePath, originalName)}>
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </button>&nbsp;
                                                <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => DeleteSelectedPDF(id, originalName, filePath)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                </div>

                <div className='row grid d-flex justify-content-center'>
                    <div className='col-12 m-2 py-2'>
                        <LabelDecoration label={`添付画像`}/>
                        <div className="row">
                            { row.imageFiles.map((data: FileProps, index)=>{
                                const { id, filePath, originalName } = data;
                                return (
                                    <div key={id} className="col-6 col-sm-6 col-md-4 py-1 mh-100 position-relative">
                                        <CustomizeCloseBtn 
                                            id={`pastContractorInformation${index}`} 
                                            text={`過去の契約者情報 (${index+1})の削除`} 
                                            index={index}
                                            onClick={() => DeleteSelectedImage(id, originalName, filePath)}
                                        />
                                        <Card
                                            body
                                            className="text-center justify-content-center h-100"
                                            style={{ padding: 0 }}
                                        >
                                            { !!filePath &&
                                                <img
                                                    alt="Card cap"
                                                    src={`${process.env.REACT_APP_File_URI}image/${filePath}`}
                                                    width="100%"
                                                />
                                            }
                                        </Card>
                                    </div>
                                )
                            })}
                            <div key={"defaultImagebutton"} className="col-6 col-sm-6 col-md-4 py-1 mh-100">
                                <Card
                                    body
                                    className="text-center justify-content-center h-100"
                                    style={{ cursor: 'pointer', minHeight: '200px' }}
                                    onClick={AddImageFile}
                                >
                                    <div className='fs-3 px-3 py-1'>
                                        <FontAwesomeIcon icon={faAdd} />
                                    </div>
                                    ファイルの選択
                                </Card>
                            </div>
                            <input type="file" id="fileupload" ref={imageFilesSelect} className="d-none" onChange={ImageInputChange} accept="image/*" />
                        </div>
                    </div>
                </div>
                <DetailItem 
                    label={`備考`}
                    text={row.note}
                    editClick={()=>{
                        setIsOpenEditModel(true);
                        setEditMode({...editMode, note: true});
                    }}
                />
                <div className='col-12 my-2'>
                    <div className="my-3 d-grid">
                        <Button color="primary" onClick={handleDeleteCustomer}>
                            削除する
                        </Button>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <Button 
                        className="small text-black py-2 bg-white border-0" onClick={redirectCustomerList}>
                        顧客一覧に戻る <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                </div>
            </Container>
            <CustomerEditModel 
                open={isOpenEditModel} 
                row={row} 
                editMode={editMode} 
                handleClose = {closeEditModel}
                handleSave = {saveEditModel}
            />
            <DetailPDFPreview 
                open={preView.open}
                title={preView.title}
                path={preView.path}
                handleClose={closePDFPreview}
            />
            <ToastContainer />
            <ContractDocumentModel 
                open={contractDocument.open} 
                id={contractDocument.id}
                detailId={contractDocument.detailId}
                userId={contractDocument.userId}
                title={contractDocument.title}
                fullName={contractDocument.fullName}
                address={contractDocument.address}
                zipCode={contractDocument.zipCode}
                createAt={contractDocument.createAt}
                productRecord={contractDocument.productRecord}
                depositAccount={contractDocument.depositAccount}
                paymentDeadline={contractDocument.paymentDeadline}
                remarks={contractDocument.remarks}
                electronicSeal={contractDocument.electronicSeal}
                respectType={contractDocument.respectType}
                handleCloseModel={closeContractDocumentModel}
                handleSaveModel={saveContractDocumentModel}
            />
            <DeleteModal 
                open={deleteData.open}
                title={deleteData.title}
                content={deleteData.content}
                close={handleDeleteCancel}
                agree={handleDeletePDF}
            />
            <DeleteCustomer
                open={deleteCustomerData.open}
                title={deleteCustomerData.title}
                close={handleDeleteCustomerCancel}
                agree={handleDeleteCustomerOK}
            />
        </DetailTemplate>
    )
}

export default CustomerDetail;