import { AuthenticatedApp, UnauthenticatedApp } from 'layouts';
import { Route, Routes } from 'react-router-dom';
import { 
    ForgotPassword, Login, NoMatch, ResetPassword,
    CustomerList, CustomerDetailPage, AddNewCustomer, AccountManagement, Logout
} from './routes';

function App() {
    return (
        <Routes>
            {/* Authenticated routes */}
            <Route element={<AuthenticatedApp />}>
                <Route path="/" element={<CustomerList />} />
                <Route path="/detail/:id" element={<CustomerDetailPage />} />
                <Route path="/add" element={<AddNewCustomer />} />
                <Route path="/account" element={<AccountManagement />} />
                <Route path="/logout" element={<Logout />} />
            </Route>

            {/* Unauthenticated routes */}
            <Route element={<UnauthenticatedApp />}>
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="password-reset" element={<ResetPassword />} />
            </Route>

            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
}

export default App;
