import axios from 'axios';

const setSession = (accessToken: any) => {
    if (accessToken) {
      localStorage.setItem('customer-token', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('customer-token');
      delete axios.defaults.headers.common.Authorization;
    }
};

const getAll = () => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/acount/all`, {});
};

const saveData = (data: any) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/acount/edit`, data);
}

const deleteData = (data: any) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/acount/delete`, data);
}

const ShortcutTemplateHelper = {
    getAll,
    saveData,
    deleteData
};

export default ShortcutTemplateHelper;