import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SidenavNavigation, SidenavNavigationItems } from '@typedbootstrap/admin-components';
import { Link } from 'react-router-dom';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from 'hooks';
import {
    NavbarToggler,
    Navbar,
    NavbarBrand,
} from 'reactstrap';
import { uiActions } from 'store/uiSlice';
export interface SidenavProps {}

const Sidenav: React.FC<SidenavProps> = () => {
    const items: SidenavNavigationItems = [
        {
            name: '顧客一覧',
            tag: Link,
            icon: <FontAwesomeIcon icon={faChevronRight} />,
            to: '/',
            className: 'sideNavLink d-flex justify-content-between flex-row-reverse'
        },
        {
            name: '新規顧客登録',
            tag: Link,
            icon: <FontAwesomeIcon icon={faChevronRight} />,
            to: '/add',
            className: 'sideNavLink d-flex justify-content-between flex-row-reverse'
        },
        {
            name: 'アカウント発行',
            tag: Link,
            icon: <FontAwesomeIcon icon={faChevronRight} />,
            to: '/account',
            className: 'sideNavLink d-flex justify-content-between flex-row-reverse'
        },
        // {
        //     name: 'パスワード変更',
        //     tag: Link,
        //     icon: <FontAwesomeIcon icon={faTachometerAlt} />,
        //     to: '/changePassword',
            // className: 'sideNavLink'
        // },
        {
            name: 'ログアウト',
            tag: Link,
            // icon: <FontAwesomeIcon icon={faTachometerAlt} />,
            to: '/logout',
            className: 'sideNavLinkLogout d-flex justify-content-center bg-light'
        },
    ];

    const dispatch = useAppDispatch();

    const toggleSidenav = (): void => {
        dispatch(uiActions.toggleSetting('sidebarToggled'));
    };

    return <>
        <Navbar color="faded" style={{ background: "#3A62AD", color: "white" }}>
            <NavbarBrand tag={Link} to="/">
                <img src={process.env.REACT_APP_LOGO} style={{ marginLeft: 15 }} width={"40px"} alt='logo' />
            </NavbarBrand>
            <NavbarToggler className="ms-auto ms-md-0 me-3 me-lg-4 border-0" onClick={toggleSidenav}>
                <FontAwesomeIcon icon={faClose} />
            </NavbarToggler>
        </Navbar>
        <SidenavNavigation items={items} style={{ background: "#3A62AD", color: "white" }} onClick={toggleSidenav} />
    </>;
};

export default Sidenav;
