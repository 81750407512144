import React from "react";
import { PDFViewer, Document, Page, StyleSheet, Text, View, Image, Font } from '@react-pdf/renderer';

interface ProductRecordProps {
    id: string;
    productName: string;
    quantity: number;
    amount: number;
    inputType: boolean;
}

interface DepositAccountProps {
    id: string;
    bankName: string;
    branchName: string;
    depositSubject: string;
    accountNumber: string;
    accountHolder: string;
}

interface ElectronicSealProps {
    changedfile: string;
}

export interface PDFRenderProps {
    id: string;
    userId: string;
    title: string;
    fullName: string;
    address: string;
    zipCode: string;
    createAt: Date|string|null;
    productRecord: Array<ProductRecordProps>;
    depositAccount: Array<DepositAccountProps>;
    paymentDeadline: string;
    remarks: string;
    electronicSeal: ElectronicSealProps|null;
    respectType: boolean;
};
// Register Font
Font.register({
    family: "Noto Sans Japanese",
    src: "https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.woff",
    fontStyle: 'normal', 
    fontWeight: 'normal', 
    // fonts?: []
});

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: "Noto Sans Japanese",
        padding: '30px',
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#707070',
        borderBottom: '1px',
        padding: 15
    },
    createdText: {
        textAlign: 'right',
        padding: '10px 0',
        fontSize: 12,
    },
    content_body: {
        flexDirection: "row",
        flexWrap: "wrap",
        fontSize: 12,
    },
    electronicSeal: {
        position: 'absolute',
        right: 10,
        top: -10,
        width: '100px',
        height: 'auto',
        zIndex: 1,
    },
    address: {
        position: 'absolute',
        fontSize: 10,
        left: 120,
        top: 37,
        width: '150px',
        height: 'auto',
        zIndex: 1,
    },
    content_section1: {
        width: "50%",
    },
    content_section2: {
        width: "42%",
    },
    py_5: {
        paddingVertical: 5
    },
    pb_5: {
        paddingBottom: 5
    },
    pt_5: {
        paddingTop: 5
    },
    font_14: {
        fontSize: 14
    },
    font_12: {
        fontSize: 12,
        paddingVertical: 1
    },
    font_10: {
        fontSize: 10
    },
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        paddingVertical: 10,
        fontSize: 14,
        borderColor: "#707070"
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        textAlign: 'center',
    },
    table_name: {
        width: "33.33%",
        borderStyle: 'solid',
        borderColor: '#707070',
        borderTop: '1px',
        padding: 4
    },
    table_name_border_left: {
        borderStyle: 'solid',
        borderColor: '#707070',
        borderLeft: '1px',
    },
    table_name_border_null: {
        width: "33.33%",
        padding: 4
    },
    table_quantity: {
        width: "33.33%",
        borderStyle: 'solid',
        borderColor: '#707070',
        borderLeft: '1px',
        borderTop: '1px',
        padding: 4
    },
    table_amount: {
        width: "33.33%",
        borderStyle: 'solid',
        borderColor: '#707070',
        borderLeft: '1px',
        borderTop: '1px',
        borderRight: '1px',
        padding: 4
    },
    table_border_bottom: {
        borderStyle: 'solid',
        borderColor: '#707070',
        borderBottom: '1px',
    },
    backgroundColor_blue: {
        backgroundColor: '#2E87FB'
    },
    depositBody: {
        fontSize: 10,
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderStyle: 'solid',
        borderColor: '#707070',
        border: '1px',
    },
    labelDecoration: {
        borderStyle: 'solid',
        borderLeft: '3px',
        borderColor: '#2E87FB',
        paddingLeft: 10,
        fontSize: 14,
    },
    paymentDeadline: {
        textAlign: 'right',
        fontSize: 12
    },
    remark: {
        fontSize: 10,
        padding: 10,
        marginTop: 10,
        borderStyle: 'solid',
        borderColor: '#707070',
        border: '1px',
    }
});

const PDFRender: React.FC<{ contractDocument: PDFRenderProps }> = ({ contractDocument }) => {
    return (
        // <PDFViewer style={{ width: '100%', height: '800px'}}>
            <Document>
                <Page wrap size="A4" style={styles.page}>
                    <View style={styles.address}>
                        <Text>{`${contractDocument.fullName}${(!contractDocument.respectType)?" 御中":" 様"}`}</Text>
                        <Text>{contractDocument.zipCode||"zipCode"}</Text>
                        <Text>{contractDocument.address}</Text>
                    </View>
                    <Text style={styles.title}>{contractDocument.title==="請求書作成"?"請求書":"見積書"}</Text>
                    <Text style={styles.createdText}>{ convertTime(contractDocument.createAt as Date) }</Text>
                    <View style={styles.content_body}>
                        { !!contractDocument.electronicSeal &&!!contractDocument.electronicSeal.changedfile ?
                            <Image
                                style={styles.electronicSeal}
                                src={contractDocument.electronicSeal.changedfile}
                            />: 
                            <Image
                                style={styles.electronicSeal}
                                src={`${process.env.REACT_APP_File_URI}img/seal.png`}
                            />
                        }
                        <View style={styles.content_section1}>
                            <Text style={[styles.pb_5, styles.font_14]}>{`${contractDocument.fullName}${(!contractDocument.respectType)?"御中":"様"}`}</Text>
                            <Text style={[styles.py_5, styles.font_10]}>下記の通りご請求申し上げます。</Text>
                            <Text style={[styles.py_5, styles.font_12]}>{`${contractDocument.title==="請求書作成"?"ご請求金額":"お見積もり金額"} ${priceFormating(subTotal(contractDocument).total)}`}</Text>
                        </View>
                        <View style={styles.content_section2}>
                            <Text style={styles.font_14}>キユウトーテクノ株式会社</Text>
                            <Text style={styles.font_12}>代表取締役 柴田 真人</Text>
                            <Text style={styles.font_12}>〒702-8047</Text>
                            <Text style={styles.font_12}>岡山県岡山市南区若葉町12-2</Text>
                            <Text style={styles.font_12}>TEL：086-259-2297</Text>
                            <Text style={styles.font_12}>登録番号：T2-2600-0103-4762</Text>
                        </View>
                    </View>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={[styles.table_name, styles.table_name_border_left, styles.backgroundColor_blue]}>品名</Text>
                            <Text style={[styles.table_quantity, styles.backgroundColor_blue]}>数量</Text>
                            <Text style={[styles.table_amount, styles.backgroundColor_blue]}>金額</Text>
                        </View>
                        { contractDocument.productRecord.map((item: ProductRecordProps, index)=>(
                            <View key={item.productName+index} style={styles.row}>
                                <Text style={[styles.table_name, styles.table_name_border_left]}>{ !!item.productName?item.productName:'商品名なし' }</Text>
                                <Text style={styles.table_quantity}>{item.quantity}</Text>
                                <Text style={styles.table_amount}>{priceFormating(item.amount*item.quantity)}</Text>
                            </View>
                        ))}
                        <View style={styles.row}>
                            <Text style={styles.table_name}>  </Text>
                            <Text style={styles.table_quantity}>小計</Text>
                            <Text style={styles.table_amount}>{priceFormating(subTotal(contractDocument).subtotal)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.table_name_border_null}>  </Text>
                            <Text style={styles.table_quantity}>消費税（10%）</Text>
                            <Text style={styles.table_amount}>{priceFormating(subTotal(contractDocument).saleTax)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.table_name_border_null}>  </Text>
                            <Text style={[styles.table_quantity, styles.table_border_bottom]}>合計金額</Text>
                            <Text style={[styles.table_amount, styles.table_border_bottom]}>{priceFormating(subTotal(contractDocument).total)}</Text>
                        </View>
                    </View>
                    {contractDocument.title==="請求書作成" &&
                        <View style={styles.depositBody}>
                            { contractDocument.depositAccount.map((item: DepositAccountProps, index)=>(
                                <View key={`deposit_document${index}`} style={styles.py_5}>
                                    <Text style={styles.labelDecoration}>{`振込口座 (${index+1})`}</Text>
                                    <View style={[styles.font_12, styles.py_5]}>
                                        <Text>{item.bankName}</Text>
                                        <Text>{item.branchName}</Text>
                                        <Text>{item.depositSubject}</Text>
                                        <Text>{item.accountNumber}</Text>
                                        <Text>{item.accountHolder}</Text>
                                    </View>
                                </View>
                            ))}
                            <Text style={styles.paymentDeadline}>{contractDocument.paymentDeadline}</Text>
                        </View>
                    }
                    <View style={styles.remark}>
                        <Text style={styles.labelDecoration}>備考</Text>
                        <Text style={styles.font_10}>
                            {contractDocument.remarks}
                        </Text>
                    </View>
                </Page>
            </Document>
        // </PDFViewer>
    )
}

export default PDFRender;


const convertTime = (d: Date|null) => {
    if(!d) return "";
    const date = new Date(d);

    return date.toLocaleString('ja-JP', {
        year: 'numeric',
        month: 'long', // Full month name
        day: 'numeric',
        timeZone: 'Asia/Tokyo' // Specify the timezone if needed
    });
};

const priceFormating = (p: number) => {
    const option = { style: 'decimal', maximumFractionDigits: 0 };
    return '￥'+p.toLocaleString('en-US', option);
}

const subTotal = (contractDocument: PDFRenderProps) => {
    let subT = 0;
    for (let i = 0; i < contractDocument.productRecord.length; i++) {
        const product = contractDocument.productRecord[i];
        subT += (product.amount * product.quantity);
    }
    return {subtotal: subT, saleTax: subT*0.1, total: subT*1.1};
}