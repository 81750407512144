import React, { useState, useEffect } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

export type ResetFormValues = {
    token: string;
    password: string;
    passwordConfirm: string;
};

export type ResetFormHelpers = FormikHelpers<ResetFormValues>;

export interface RegisterFormProps {
    initialValues: ResetFormValues;
    onSubmit: (values: ResetFormValues, helpers: ResetFormHelpers) => void;
}

const ResetForm: React.FC<RegisterFormProps> = props => {
    const _token = new URLSearchParams(window.location.search).get('token');
    const [ token, setToken ] = useState<string>();
    useEffect(()=>{
        if(!!_token) setToken(_token);
    }, [_token])
    if(!token) return <div></div>
    else return (
        <Formik initialValues={{ ...props.initialValues, token }} onSubmit={props.onSubmit}>
            {form => (
                <Form onSubmit={form.handleSubmit}>
                    <FormGroup>
                        <Label for="password">新しいパスワード</Label>
                        <Input
                            id="password"
                            type="password"
                            name="password"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            placeholder="Create a password"
                            value={form.values.password}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="passwordConfirm">新しいパスワードの確認</Label>
                        <Input
                            id="passwordConfirm"
                            type="password"
                            name="passwordConfirm"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            placeholder="Confirm password"
                            value={form.values.passwordConfirm}
                        />
                    </FormGroup>
                    <Button type="submit" color="primary" block>
                        パスワードを変更する
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default ResetForm;
