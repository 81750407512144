import React from 'react';
import { CustomerListPage } from 'components/pages';

export interface CustomerListProps {}

const CustomerList: React.FC<CustomerListProps> = () => {
    return <CustomerListPage />;
};

export default CustomerList;
