import React from 'react';
import { faUser, faLocationDot, faStar, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import classnames from 'classnames';
import { 
    Card, CardBody, CardHeader, Button
} from 'reactstrap';

interface PastCustomerInfoProps {
    firstName: string;
    lastName: string;
    furiganaFirstName: string;
    furiganaLastName: string;
}

export interface WidgetProps {
    bg?: string;
    text?: string;
    // children?: React.ReactNode;
    className?: string;
    surname1: string;
    surname2: string;
    pastCustomers: Array<PastCustomerInfoProps>;
    address: string;
    plusCode: string;
    bookmark: string;
    handlePlusCode: ()=>void;
    handleBookmark: ()=>void;
    handleDetail: ()=>void;
}

const Widget: React.FC<WidgetProps> = props => {
    const { 
        // bg, className, text, 
        surname1, surname2, address, plusCode, bookmark,
        pastCustomers, handlePlusCode, handleBookmark, handleDetail
    } = props;

    // const classNames: string = classnames({ [`bg-${bg}`]: bg, [`text-${text}`]: text }, className);

    return (
        // <Card className={classNames}>
        <Card style={{ height: '100%' }}>
            <CardHeader style={{ backgroundColor: '#3A62AD' }}>
                <div className='d-flex justify-content-between text-white px-2'>
                    <div className='d-flex align-self-center'>
                        <FontAwesomeIcon icon={faUser} fontSize={20} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div>
                        <div className="mt-1 text-center" style={{ fontSize: '12px' }}>{surname2}</div>
                        <div className='text-center bg-[#3A62AD]' style={{ fontSize: '16px' }}>{surname1}</div>
                    </div>
                    <div className='d-flex align-self-center'>
                        <div onClick={handlePlusCode} style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon 
                                icon={faLocationDot} 
                                color={plusCode==="選択済み"?'#E04B56':''} 
                                fontSize={20} 
                            />
                        </div>
                        &nbsp;&nbsp;
                        <div onClick={handleBookmark} style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon 
                                icon={faStar} 
                                color={bookmark==="選択済み"?'yellow':''} 
                                fontSize={20} 
                            />
                        </div>
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                <div className='px-1'>
                    { pastCustomers.map((item: PastCustomerInfoProps, index)=>(
                        <div key={`pastcustomer${index}`}>
                            { pastCustomers.length === 1 ?
                                <>
                                    {(item.firstName===""&&item.lastName===""&&item.furiganaFirstName===""&&item.furiganaLastName==="")?
                                        <></>:
                                        <>
                                            <div className='mt-2 fw-bold'>{`過去の契約者情報`}</div>
                                            <div className='text-base'>{`${item.firstName} ${item.lastName}（${item.furiganaFirstName} ${item.furiganaLastName}）`}</div>
                                        </>
                                    }
                                </>:
                                <>
                                    {(item.firstName===""&&item.lastName===""&&item.furiganaFirstName===""&&item.furiganaLastName==="")?
                                        <></>:
                                        <>
                                            <div className='mt-2 fw-bold'>{`過去の契約者情報 (${index+1})`}</div>
                                            <div className='text-base'>{`${item.firstName} ${item.lastName}（${item.furiganaFirstName} ${item.furiganaLastName}）`}</div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    ))}
                    <div className='mt-3 fw-bold'>住所</div>
                    <div>{address}</div>
                </div>
            </CardBody>
            <div className="d-flex align-items-center justify-content-center mb-4">
                <Button className="small text-black py-2 bg-white border-0" onClick={handleDetail}>
                    詳しく見る <FontAwesomeIcon icon={faAngleRight} />
                </Button>
            </div>
        </Card>
    );
};

export default Widget;
