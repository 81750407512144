import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, Input, FormGroup, Label, InputGroup, InputGroupText, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { 
    LabelDecoration, CustomizeAddButton, CustomizeCloseBtn, PDFRender
} from "components/organisms";
import { PDFDownloadLink } from '@react-pdf/renderer';
const { v4: uuidv4 } = require('uuid');   

interface ContractDocumentProps {
    id: string;
    userId: string;
    detailId: string|undefined;
    title: string;
    fullName: string;
    address: string;
    zipCode: string;
    createAt: Date|string|null;
    productRecord: Array<ProductRecordProps>;
    depositAccount: Array<DepositAccountProps>;
    paymentDeadline: string;
    remarks: string;
    electronicSeal: ElectronicSealProps|null;
    respectType: boolean;
}

export interface ContractDocumentModelProps {
    id: string;
    open: boolean;
    userId: string;
    detailId: string|undefined;
    title: string;
    fullName: string;
    address: string;
    zipCode: string;
    createAt: Date|string|null;
    productRecord: Array<ProductRecordProps>;
    depositAccount: Array<DepositAccountProps>;
    paymentDeadline: string;
    remarks: string;
    electronicSeal: ElectronicSealProps|null;
    respectType: boolean;
    handleCloseModel: ()=>void;
    handleSaveModel: (data: ContractDocumentProps)=>void;
};

interface ProductRecordProps {
    id: string;
    productName: string;
    quantity: number;
    amount: number;
    inputType: boolean;
}

interface DepositAccountProps {
    id: string;
    bankName: string;
    branchName: string;
    depositSubject: string;
    accountNumber: string;
    accountHolder: string;
}

interface ElectronicSealProps {
    changedfile: string;
}

interface BankSort {
    banksort: string|undefined;
}

const ContractDocumentModel: React.FC<ContractDocumentModelProps> = ({ 
    open, id, userId, detailId, title, fullName, address, zipCode, createAt,
    productRecord, depositAccount, paymentDeadline, remarks, electronicSeal, respectType, 
    handleCloseModel, handleSaveModel 
}) => {

    const [ contractDocument, setContractDocument ] = useState<ContractDocumentProps>({
        id: "",
        userId: "",
        detailId: "",
        title: "",
        fullName: "",
        address: "",
        zipCode: "",
        respectType: false,
        createAt: new Date(),
        productRecord: [{
            id: "",
            productName: "",
            quantity: 0,
            amount: 0,
            inputType: false,
        }],
        depositAccount: [],
        paymentDeadline: "お支払い期日：請求書の受領から14日以内",
        remarks: "",
        electronicSeal: null,
    });
    
    const [bankSelect, setBankSelect] = useState<BankSort[]>([]);

    useEffect(()=>{
        if(open){
            if(id==="new"){
                setContractDocument({
                    id: 'new',
                    userId,
                    detailId,
                    title,
                    fullName,
                    address,
                    zipCode,
                    createAt: new Date(),
                    productRecord: [{
                        id: uuidv4(),
                        productName: "",
                        quantity: 0,
                        amount: 0,
                        inputType: false
                    }],
                    depositAccount: (title === "請求書作成")?[{
                        id: uuidv4(),
                        bankName: "",
                        branchName: "",
                        depositSubject: "",
                        accountNumber: "",
                        accountHolder: ""
                    }]:[],
                    paymentDeadline: "お支払い期日：請求書の受領から14日以内",
                    remarks: "",
                    electronicSeal: null,
                    respectType: false,
                });
            }else {
                setContractDocument({
                    id, userId, detailId, title, fullName,
                    address, zipCode, createAt, productRecord, depositAccount,
                    paymentDeadline, remarks, electronicSeal, respectType
                });
            }
        }
    }, [open]);

    const addProductRecord = () => {
        // Create a new UpdateAtProps object
        const newProductRecord: ProductRecordProps = {
            id: uuidv4(),
            productName: "",
            quantity: 0,
            amount: 0,
            inputType: false
        };
        // Create a copy of the existing ECFAddedDate array
        const updatedProductRecord = [...contractDocument.productRecord];
        // Add the new PastCustomerInfoProps object to the copied array
        updatedProductRecord.push(newProductRecord);
        // Update the contractDocument state with the copied array
        setContractDocument({
            ...contractDocument,
            productRecord: updatedProductRecord,
        });
    }

    
    const removeCustomerInfo = (index: number) =>{
        const updatedProductRecord = [...contractDocument.productRecord];
        updatedProductRecord.splice(index, 1);
        setContractDocument({
            ...contractDocument,
            productRecord: updatedProductRecord,
        });
    }
    // ----------------------------------
    const addDepositAccount = () => {
        // Create a new UpdateAtProps object
        const newDepositAccount: DepositAccountProps = {
            id: uuidv4(),
            bankName: "",
            branchName: "",
            depositSubject: "",
            accountNumber: "",
            accountHolder: ""
        };

        // Create a copy of the existing ECFAddedDate array
        const updatedDepositAccount = [...contractDocument.depositAccount];

        // Add the new PastCustomerInfoProps object to the copied array
        updatedDepositAccount.push(newDepositAccount);

        // Update the contractDocument state with the copied array
        setContractDocument({
            ...contractDocument,
            depositAccount: updatedDepositAccount,
        });
    }

    const removeDepositAccount = (index: number) => {
        const updatedDepositAccount = [...contractDocument.depositAccount];
        updatedDepositAccount.splice(index, 1);
        setContractDocument({
            ...contractDocument,
            depositAccount: updatedDepositAccount,
        });
    }

    const imageFilesSelect = useRef<HTMLInputElement>(null);
    const AddImageFile = () => {
        if (imageFilesSelect.current && !contractDocument.electronicSeal?.changedfile) {
            imageFilesSelect.current.click();
        }
    }
    
    const ImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (file) {
                        const selectedFile: ElectronicSealProps = {
                            changedfile: reader.result as string,
                        };
                        setContractDocument({
                            ...contractDocument, electronicSeal: selectedFile,
                        });
                    }
                }
                if (file) {
                    reader.readAsDataURL(file);
                }
            }
            // Ensure that the ref is initialized before accessing its value
            if (imageFilesSelect.current) {
                // Reset the value of the file input
                imageFilesSelect.current.value = '';
            }
        }
    };

    return (
        <Modal isOpen={open} toggle={handleCloseModel} fullscreen>
            <div 
                className='d-flex justify-content-between mt-3 px-5 py-4' 
                style={{ 
                    borderStyle: "solid",
                    borderWidth: "0 0 1px 0",
                    borderColor: '#dee2e6'
                }}
            >
                <p className='filter_back' onClick={handleCloseModel}></p>
                <h5 style={{ fontSize: "1.125rem", marginBottom:0 }}>{title}</h5>
                <p className='filter_back' style={{visibility:"hidden"}}>
                </p>
            </div>
            <ModalBody>
                <div className='row grid d-flex justify-content-center'>
                    <div className='col-12 m-2 py-0' style={{marginTop: 0}}>
                        <LabelDecoration label={`宛名`}/>
                        <div className="mt-1">
                            <Input
                                id="fullName"
                                name="fullName"
                                type="text"
                                value={contractDocument.fullName}
                                onChange={(e)=>{setContractDocument({
                                    ...contractDocument, fullName: e.target.value
                                })}}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-row'>
                        <Label check>「御中」から「様」 に変更する</Label>&nbsp;&nbsp;
                        <FormGroup switch>
                            <Input
                                type="switch"
                                checked={contractDocument.respectType}
                                onChange={(e) => {setContractDocument({ ...contractDocument, respectType: e.target.checked })}}
                            />
                        </FormGroup>
                        
                    </div>
                    <div className='col-12 m-2 py-0' style={{marginTop: 0}}>
                        <LabelDecoration label={`郵便番号`}/>
                        <div className="mt-1">
                            <Input
                                id="zipCode"
                                name="zipCode"
                                type="text"
                                value={contractDocument.zipCode}
                                onChange={(e)=>{setContractDocument({
                                    ...contractDocument, zipCode: e.target.value
                                })}}
                            />
                        </div>
                    </div>
                    <div className='col-12 m-2 py-0' style={{marginTop: 0}}>
                        <LabelDecoration label={`住所`}/>
                        <div className="mt-1">
                            <Input
                                id="address"
                                name="address"
                                type="text"
                                value={contractDocument.address}
                                onChange={(e)=>{setContractDocument({
                                    ...contractDocument, address: e.target.value
                                })}}
                            />
                        </div>
                    </div>
                    <div className='col-12 mx-2 py-0' style={{marginTop: 0}}>
                        <LabelDecoration label={`日付`}/>
                        <div className="mt-1">
                            <DatePicker
                                dateFormat="yyyy/MM/dd"
                                selected={contractDocument.createAt as Date}
                                onChange={(date) => {setContractDocument({
                                    ...contractDocument, createAt: date
                                })}}
                            />
                        </div>
                    </div>
                </div>
                {/*  */}
                { contractDocument.productRecord.map((item: ProductRecordProps, index)=>(
                    <div key={`productRecord${index}`} className='row grid d-flex justify-content-center my-3'>
                        <div className='col-12 mx-2 my-1 py-0' style={{marginTop: 0}}>
                            { contractDocument.productRecord.length>1&&
                                <CustomizeCloseBtn 
                                    id={`pastContractorInformation${index}`} 
                                    text={`過去の契約者情報 (${index+1})の削除`} 
                                    index={1}
                                    onClick={(e)=>{removeCustomerInfo(e)}}
                                />
                            }
                            <LabelDecoration label={`品名レコード (${index+1})`}/>
                            <div className="mt-1">
                                <Input
                                    hidden={item.inputType}
                                    id={`productNameCategory${index}`}
                                    name={`productNameCategory${index}`}
                                    type="select"
                                    className='c_select'
                                    value={item.productName}
                                    onChange={(e)=>{
                                        const updatedProductName = [...contractDocument.productRecord];
                                        updatedProductName[index].productName = e.target.value;
                                        setContractDocument({ ...contractDocument, productRecord: updatedProductName });
                                    }}
                                >
                                    <option value={''}></option>
                                    <option value={'洗浄メンテナンス'}> 洗浄メンテナンス</option>
                                    <option value={'エコキュート取付工事費'}>エコキュート取付工事費</option>
                                    <option value={'作業費'}>作業費</option>
                                    <option value={'出張費'}>出張費</option>
                                    <option value={'部品代'}>部品代</option>
                                    <option value={'付帯作業費'}>付帯作業費</option>
                                    <option value={'諸経費'}>諸経費</option>
                                    <option value={'補助材料費'}>補助材料費</option>
                                </Input>
                                <Input
                                    hidden={!item.inputType}
                                    id="googleMapCode"
                                    name="googleMapCode"
                                    type="text"
                                    value={item.productName}
                                    onChange={(e)=>{
                                        const updatedProductName = [...contractDocument.productRecord];
                                        updatedProductName[index].productName = e.target.value;
                                        setContractDocument({ ...contractDocument, productRecord: updatedProductName });
                                    }}
                                />
                            </div>
                            <div className='d-flex flex-row mt-2'>
                                <Label check>品名を自分で入力する</Label>&nbsp;&nbsp;
                                <FormGroup switch>
                                    <Input
                                        type="switch"
                                        checked={item.inputType}
                                        onChange={(e) => {
                                            const updatedProductName = [...contractDocument.productRecord];
                                            updatedProductName[index].inputType = e.target.checked;
                                            setContractDocument({ ...contractDocument, productRecord: updatedProductName });
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='col-12 mx-2'>
                            <div className="row grid d-flex justify-content-center">
                                <div className="col-6 g-col-6">
                                    <div style={{marginBottom: '5px'}}>数量</div>
                                    <Input
                                        id={`quantity${index}`}
                                        name={`quantity${index}`}
                                        type="number"
                                        value={item.quantity}
                                        onChange={(e)=>{
                                            const updatedProductName = [...contractDocument.productRecord];
                                            updatedProductName[index].quantity = parseInt(e.target.value, 10);
                                            setContractDocument({ ...contractDocument, productRecord: updatedProductName });
                                        }}
                                    />
                                </div>
                                <div className="col-6 g-col-6">
                                    <div style={{marginBottom: '5px'}}>金額（税抜き）</div>
                                    <InputGroup>
                                        <InputGroupText style={{backgroundColor: "#fff"}}>￥</InputGroupText>
                                        <Input
                                            id="lastName"
                                            name="lastName"
                                            type="number"
                                            value={item.amount}
                                            onChange={(e)=>{
                                                const updatedProductName = [...contractDocument.productRecord];
                                                updatedProductName[index].amount = parseInt(e.target.value, 10);
                                                setContractDocument({ ...contractDocument, productRecord: updatedProductName });
                                            }}
                                        />
                                    </InputGroup>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <CustomizeAddButton id='customerInfoAddBtn' text='過去の契約者情報の追加' onClick={addProductRecord} />
                
                {/* ============================================= */}
                { contractDocument.depositAccount.map((item: DepositAccountProps, index)=>(
                    <div key={`depositAccount${index}`} className='row grid d-flex justify-content-center my-3'>
                        <div className='col-12 m-2 py-0' style={{marginTop: 0}}>
                            { contractDocument.depositAccount.length>1 &&
                                <CustomizeCloseBtn 
                                    id={`pastContractorInformation${index}`} 
                                    text={`過去の契約者情報 (${index+1})の削除`} 
                                    index={index}
                                    onClick={(e)=>{removeDepositAccount(e)}}
                                />
                            }
                            <LabelDecoration label={`振込口座 (${index+1})`}/>
                            <div className="mt-1">
                                <div>銀行選択</div>
                                <Input
                                    id={`bankName${index}`}
                                    name={`bankName${index}`}
                                    type="select"
                                    className='c_select'
                                    value={bankSelect[index]?.banksort||""}
                                    onChange={(e)=>{
                                        const banksort = e.target.value;
                                        const bankselect = bankSelect;
                                        bankselect[index] = {banksort};
                                        setBankSelect(bankselect);
                                        const updatedInfo = [...contractDocument.depositAccount];
                                        switch (banksort) {
                                            case "2494092":
                                                updatedInfo[index].bankName = "トマト銀行";
                                                updatedInfo[index].branchName = "岡山南営業部";
                                                updatedInfo[index].depositSubject = "普通預金";
                                                updatedInfo[index].accountNumber = "２４９４０９２";
                                                updatedInfo[index].accountHolder = "キユウトーテクノ株式会社";
                                                setContractDocument({ ...contractDocument, depositAccount: updatedInfo });
                                                break;

                                            case "2493992":
                                                updatedInfo[index].bankName = "トマト銀行";
                                                updatedInfo[index].branchName = "岡山南営業部";
                                                updatedInfo[index].depositSubject = "普通預金";
                                                updatedInfo[index].accountNumber = "２４９３９９２";
                                                updatedInfo[index].accountHolder = "キユウトーテクノ株式会社";
                                                setContractDocument({ ...contractDocument, depositAccount: updatedInfo });
                                                break;

                                            default:
                                                updatedInfo[index].bankName = "";
                                                updatedInfo[index].branchName = "";
                                                updatedInfo[index].depositSubject = "";
                                                updatedInfo[index].accountNumber = "";
                                                updatedInfo[index].accountHolder = "";
                                                setContractDocument({ ...contractDocument, depositAccount: updatedInfo });
                                                break;
                                        }
                                    }}
                                >
                                    <option value={''}></option>
                                    <option value={'2494092'}>トマト銀行（２４９４０９２）</option>
                                    <option value={'2493992'}>トマト銀行（２４９３９９２）</option>
                                </Input>
                            </div>
                            <div className="mt-1">
                                <div>銀行名</div>
                                <Input
                                    id={`bankName${index}`}
                                    name={`bankName${index}`}
                                    type="text"
                                    value={item.bankName}
                                    onChange={(e)=>{
                                        const updatedBankName = [...contractDocument.depositAccount];
                                        updatedBankName[index].bankName = e.target.value;
                                        setContractDocument({ ...contractDocument, depositAccount: updatedBankName });
                                    }}
                                />
                            </div>
                            <div className="mt-1">
                                <div>支店名</div>
                                <Input
                                    id={`branchName${index}`}
                                    name={`branchName${index}`}
                                    type="text"
                                    value={item.branchName}
                                    onChange={(e)=>{
                                        const updatedBranchName = [...contractDocument.depositAccount];
                                        updatedBranchName[index].branchName = e.target.value;
                                        setContractDocument({ ...contractDocument, depositAccount: updatedBranchName });
                                    }}
                                />
                            </div>
                            <div className="mt-1">
                                <div>預金科目</div>
                                <Input
                                    id={`depositSubject${index}`}
                                    name={`depositSubject${index}`}
                                    type="text"
                                    value={item.depositSubject}
                                    onChange={(e)=>{
                                        const updatedDepositSubject = [...contractDocument.depositAccount];
                                        updatedDepositSubject[index].depositSubject = e.target.value;
                                        setContractDocument({ ...contractDocument, depositAccount: updatedDepositSubject });
                                    }}
                                />
                            </div>
                            <div className="mt-1">
                                <div>口座番号</div>
                                <Input
                                    id={`accountNumber`}
                                    name={`accountNumber`}
                                    type="text"
                                    value={item.accountNumber}
                                    onChange={(e)=>{
                                        const updatedAccountNumber = [...contractDocument.depositAccount];
                                        updatedAccountNumber[index].accountNumber = e.target.value;
                                        setContractDocument({ ...contractDocument, depositAccount: updatedAccountNumber });
                                    }}
                                />
                            </div>
                            <div className="mt-1">
                                <div>口座名義</div>
                                <Input
                                    id={`accountholder${index}`}
                                    name={`accountholder${index}`}
                                    type="text"
                                    value={item.accountHolder}
                                    onChange={(e)=>{
                                        const updatedAccountHolder = [...contractDocument.depositAccount];
                                        updatedAccountHolder[index].accountHolder = e.target.value;
                                        setContractDocument({ ...contractDocument, depositAccount: updatedAccountHolder });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                { title === "請求書作成" &&
                    <CustomizeAddButton id='customerInfoAddBtn' text='過去の契約者情報の追加' onClick={addDepositAccount} />
                }
                {/* =============================== */}

                <div className='row grid d-flex justify-content-center'>
                    { title === "請求書作成" &&
                        <div className='col-12 m-2 py-0' style={{marginTop: 0}}>
                            <LabelDecoration label={`支払い期日`}/>
                            <div className="mt-1">
                                <Input
                                    id="paymentDeadline"
                                    name="paymentDeadline"
                                    type="text"
                                    value={contractDocument.paymentDeadline}
                                    onChange={(e)=>{setContractDocument({
                                        ...contractDocument, paymentDeadline: e.target.value
                                    })}}
                                />
                            </div>
                        </div>
                    }
                    <div className='col-12 m-2 py-0' style={{marginTop: 0}}>
                        <LabelDecoration label={`備考`}/>
                        <div className="mt-1">
                            <Input
                                id="googleMapCode"
                                name="googleMapCode"
                                type="textarea"
                                value={contractDocument.remarks}
                                onChange={(e)=>{setContractDocument({
                                    ...contractDocument, remarks: e.target.value
                                })}}
                            />
                        </div>
                    </div>
                    <div className='col-12 m-2 py-0' style={{marginTop: 0}}>
                        <LabelDecoration label={`電子印`}/>
                        <div className="mt-1 d-flex justify-content-center"
                            style={{ cursor: 'pointer', height: '220px' }}
                            onClick={AddImageFile}
                        
                        >
                            <div className='d-flex justify-content-center border border-secondary position-relative' style={{minWidth: '300px'}}>
                                { !!contractDocument.electronicSeal ?
                                    <>
                                        <CustomizeCloseBtn 
                                            id={`electronicSeal`} 
                                            text={`電子印の削除`} 
                                            index={0}
                                            onClick={()=>{
                                                setContractDocument({
                                                    ...contractDocument, electronicSeal: null
                                                })
                                            }}
                                        />
                                        { !!contractDocument.electronicSeal.changedfile &&
                                            <img
                                                alt="Card cap"
                                                src={contractDocument.electronicSeal.changedfile}
                                            />
                                        }
                                    </>:
                                    <img
                                        alt="Card cap"
                                        src={`${process.env.REACT_APP_File_URI}img/addFile.svg`}
                                        width="50px"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <input type="file" id="fileupload" ref={imageFilesSelect} className="d-none" onChange={ImageInputChange} accept="image/*" />
                    <div className='col-12 my-3 mx-2 py-2 d-flex'>
                        <div className="d-grid p-2 flex-fill">
                            <PDFDownloadLink className='btn btn-primary' document={<PDFRender contractDocument={contractDocument} />} fileName={(title === "請求書作成"?'請求書PDF[':'見積書PDF[')+contractDocument.id+']'}>
                                {({ blob, url, loading, error }) =>
                                    loading ? 'PDFを作成中...' : 'ダウンロードする'
                                }
                            </PDFDownloadLink>
                        </div>
                        <div className="d-grid p-2 flex-fill">
                            <Button color="primary" className='p-2 flex-fill' onClick={()=>handleSaveModel(contractDocument)}>
                                保存
                            </Button>
                        </div>
                    </div>
                </div>
                {/* <PDFRender contractDocument={contractDocument} /> */}
            </ModalBody>
        </Modal>
    )
}

export default ContractDocumentModel;