import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, FormGroup, Input, Label, Button } from 'reactstrap';
import { LabelDecoration } from "components/organisms";
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FilterProps {
    open: boolean;
    keyword: string;
    category: string;
    plusCode: string;
    bookmark: string;
}

export interface FilterModelProps {
    open: boolean;
    keyword: string;
    category: string;
    plusCode: string;
    bookmark: string;
    handleClose: ()=>void;
    handleUpdate: (e: FilterProps)=>void;
}

const FilterModel: React.FC<FilterModelProps> = ({
    open, keyword, category, plusCode, bookmark, handleClose, handleUpdate
}) =>{
    const [ filter, setFilter ] = useState<FilterProps>({
        open: false,
        keyword: "",
        category: "",
        plusCode: "",
        bookmark: "",
    })

    useEffect(()=>{
        setFilter({open, keyword, category, plusCode, bookmark});
    }, [open]);

    const updateFilter = () =>{
        handleUpdate(filter);
    }

    const resetFilter = () => {
        setFilter({
            ...filter,
            category: "",
            plusCode: "",
            bookmark: "",
        })
    }

    // return <Modal isOpen={filter.open} toggle={handleClose} fullscreen>
    return <Modal isOpen={filter.open} toggle={handleClose} 
        className={"modal-fullscreen-sm-down modal-dialog-centered"}>
        <div 
            className='d-flex justify-content-between mt-3 px-5 py-4' 
            style={{ 
                borderStyle: "solid",
                borderWidth: "0 0 1px 0",
                borderColor: '#dee2e6'
            }}
        >
            <p className='filter_back' onClick={handleClose}></p>
            <h5 style={{ fontSize: "1.125rem", marginBottom:0 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 絞り込み</h5>
            <button className='border-0' style={{backgroundColor: 'white', color: '#2E87FB' }} onClick={resetFilter}>クリア</button>
        </div>
        <ModalBody>
            <div className='row grid d-flex justify-content-center'>
                <div className='col-12 m-2 py-2'>
                    <LabelDecoration label={"カテゴリ"} />
                    <div className="mt-1">
                        <Input
                            id="category"
                            name="category"
                            type="select"
                            className='c_select'
                            value={filter.category}
                            onChange={(e)=>{
                                setFilter({...filter, category: e.target.value})
                            }}
                        >
                            <option value={''}></option>
                            <option value={'工事'}>工事</option>
                            <option value={'メンテナンス'}>メンテナンス</option>
                            <option value={'点検のみ'}>点検のみ</option>
                            <option value={'未訪問'}>未訪問</option>
                            <option value={'自社工事'}>自社工事</option>
                            <option value={'自社工事＋メンテナンス'}>自社工事＋メンテナンス</option>
                            <option value={'他社入替'}>他社入替</option>
                        </Input>
                    </div>
                </div>
                
                <div className='col-12 m-2 py-2'>
                    <LabelDecoration label={"訪問ラベル"} />
                    <div className="d-flex flex-row mt-1 p-1">
                        <FormGroup check>
                            <Input
                                name="plusCode"
                                type="radio"
                                checked={""===filter.plusCode}
                                onChange={() => {
                                    setFilter({...filter, plusCode: ""});
                                }}
                            />
                            <Label check>指定なし</Label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                name="plusCode"
                                type="radio"
                                checked={"選択済み"===filter.plusCode}
                                onChange={() => {
                                    setFilter({...filter, plusCode: "選択済み"});
                                }}
                            />
                            <Label check>選択済み</Label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                name="plusCode"
                                type="radio"
                                checked={"選択なし"===filter.plusCode}
                                onChange={() => {
                                    setFilter({...filter, plusCode: "選択なし"});
                                }}
                            />
                            <Label check>選択なし</Label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </FormGroup>
                    </div>
                </div>
                
                <div className='col-12 m-2 py-2'>
                    <LabelDecoration label={"星ラベル"} />
                    <div className="d-flex flex-row mt-1 p-1">
                        <FormGroup check>
                            <Input
                                name="bookmark"
                                type="radio"
                                checked={""===filter.bookmark}
                                onChange={() => {
                                    setFilter({...filter, bookmark: ""});
                                }}
                            />
                            <Label check>指定なし</Label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                name="bookmark"
                                type="radio"
                                checked={"選択済み"===filter.bookmark}
                                onChange={() => {
                                    setFilter({...filter, bookmark: "選択済み"});
                                }}
                            />
                            <Label check>選択済み</Label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                name="bookmark"
                                type="radio"
                                checked={"選択なし"===filter.bookmark}
                                onChange={() => {
                                    setFilter({...filter, bookmark: "選択なし"});
                                }}
                            />
                            <Label check>選択なし</Label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </FormGroup>
                    </div>
                </div>
                <div className='col-12 m-2 py-3'>
                    <div className="mt-1 mb-3 d-grid">
                        <Button color="primary" onClick={updateFilter}>
                            この条件で絞り込む
                        </Button>
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>
}

export default FilterModel;