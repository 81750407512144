import React, { useState }  from "react";
import { Tooltip } from 'reactstrap';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export interface CustomizeCloseBtnProps {
    id: string,
    text: string,
    onClick: (index: number) => void;
    index: number,
};

const CustomizeCloseBtn: React.FC<CustomizeCloseBtnProps> = ({id, text, onClick, index}) =>{

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const handleOnClick = () => onClick(index);
    return <>
            <FontAwesomeIcon 
                id={id} 
                className="customize_close" 
                icon={faClose} 
                onClick={handleOnClick}
            />
            <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={id}
                toggle={toggle}
            >
                { text }
            </Tooltip>
        </>
}

export default CustomizeCloseBtn;