import React, {useState, useEffect} from "react";
import { 
    Button, Input, FormGroup, Label, 
    Modal, ModalHeader, ModalBody, ModalFooter 
} from 'reactstrap';
import DatePicker from "react-datepicker";
import { LabelDecoration } from "components/organisms";

interface PastCustomerInfoProps {
    firstName: string;
    lastName: string;
    furiganaFirstName: string;
    furiganaLastName: string;
}

interface ECFAddedDateProps {
    ecocuteModel: string;
    updateAt: Date | null; // Allow null
}

interface FileProps {
    id: string;
    filePath: string;
    originalName: string;
    createdAt: Date | null; // Allow null
}

interface ContractDocumentProps {
    open: boolean;
    id: string;
    detailId: string|undefined;
    userId: string;
    title: string;
    fullName: string;
    address: string;
    zipCode: string;
    createAt: Date|string|null;
    productRecord: Array<ProductRecordProps>;
    depositAccount: Array<DepositAccountProps>;
    paymentDeadline: string;
    remarks: string;
    electronicSeal: ElectronicSealProps|null;
    respectType: boolean
}

interface ProductRecordProps {
    id: string;
    productName: string;
    quantity: number;
    amount: number;
    inputType: boolean;
}

interface DepositAccountProps {
    id: string;
    bankName: string;
    branchName: string;
    depositSubject: string;
    accountNumber: string;
    accountHolder: string;
}

interface ElectronicSealProps {
    changedfile: string;
}

interface RowsProps {
    id:string;
    firstName: string;
    lastName: string;
    furiganaFirstName: string;
    furiganaLastName: string;
    pastCustomers: Array<PastCustomerInfoProps>;
    address: string;
    zipCode: string;
    googleMapCode: string;
    phoneNumber: string;
    contractDate: Date | null; // Allow null
    constructionDate: Date | null; // Allow null
    category: string;
    salesType: string;
    ECFAddedDate: Array<ECFAddedDateProps>;
    pdfFiles: Array<FileProps>;
    imageFiles: Array<FileProps>;
    estimageFiles: Array<ContractDocumentProps>;
    invoiceFiles: Array<ContractDocumentProps>;
    note: string;
    plusCode: boolean;
    bookmark: boolean;
    respectType: boolean;
}

interface EditPastCustomer {
    edit: boolean;
}

interface EditModeProps {
    customerName: boolean;
    pastCustomers: Array<EditPastCustomer>;
    address: boolean;
    zipCode: boolean;
    googleMapCode: boolean;
    phoneNumber: boolean;
    contractDate: boolean;
    constructionDate: boolean;
    category: boolean;
    salesType: boolean;
    ECFAddedDate: boolean;
    note:  boolean;
}

interface EcocuteType {
    type: boolean | undefined;
}

export interface CustomerEditModelProps {
    open: boolean;
    row: RowsProps;
    editMode: EditModeProps;
    handleClose: ()=>void;
    handleSave: (e: RowsProps) => void;
}

const CustomerEditModel: React.FC<CustomerEditModelProps> = ({open, row, editMode, handleClose, handleSave }) => {
    const [ editRow, setEditRow ] = useState<RowsProps>({
        id:"",
        firstName: "",
        lastName: "",
        furiganaFirstName: "",
        furiganaLastName: "",
        pastCustomers: [],
        address: "",
        zipCode: "",
        googleMapCode: "",
        phoneNumber: "",
        contractDate: null,
        constructionDate: null,
        category: "",
        salesType: "",
        ECFAddedDate: [],
        pdfFiles: [],
        imageFiles: [],
        estimageFiles: [],
        invoiceFiles: [],
        note: "",
        plusCode: false,
        bookmark: false,
        respectType: false,
    });

    const [ eMode, setEMode ] =useState<EditModeProps>({
        customerName: false,
        pastCustomers: [],
        address: false,
        zipCode: false,
        googleMapCode: false,
        phoneNumber: false,
        contractDate: false,
        constructionDate: false,
        category: false,
        salesType: false,
        ECFAddedDate: false,
        note: false,
    });

    const [ecocuteType, setEcocuteType] = useState<EcocuteType[]>([{ type: false },]);

    useEffect(()=>{
        if(open){
            setEditRow(row);
            setEMode(editMode);
            const temp = row.ECFAddedDate.map((item=>({type: !['EQ37HFV', 'EQ46HFV', 'EQ37JFV', 'EQ46JFV', 'EQ37KFV', 'EQ46KFV', 'EQ37LFV', 'EQ46LFV'].includes(item.ecocuteModel)})))
            setEcocuteType(temp);
        }
    },[open])
    return (
        <Modal isOpen={open} toggle={handleClose} 
            className={"modal-fullscreen-sm-down modal-dialog-centered"}>
                { eMode.customerName &&
                <>
                    <ModalHeader toggle={handleClose}>{`氏名`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"名前"} />
                                <div className="row grid d-flex justify-content-center mt-1">
                                    <div className="col-6 g-col-6">
                                        <Input
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            value={editRow.firstName}
                                            onChange={(e)=>{ setEditRow({ ...editRow, firstName: e.target.value }) }}
                                        />
                                    </div>
                                    <div className="col-6 g-col-6">
                                        <Input
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            value={editRow.lastName}
                                            onChange={(e)=>{setEditRow({ ...editRow, lastName: e.target.value })}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"フリガナ"} />
                                <div className="row grid d-flex justify-content-center mt-1">
                                    <div className="col-6 g-col-6">
                                        <Input
                                            id="furiganaFirstName"
                                            name="furiganaFirstName"
                                            type="text"
                                            value={editRow.furiganaFirstName}
                                            onChange={(e)=>{setEditRow({ ...editRow, furiganaFirstName: e.target.value })}}
                                        />
                                    </div>
                                    <div className="col-6 g-col-6">
                                        <Input
                                            id="furiganaLastName"
                                            name="furiganaLastName"
                                            type="text"
                                            value={editRow.furiganaLastName}
                                            onChange={(e)=>{setEditRow({ ...editRow, furiganaLastName: e.target.value })}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            { editRow.pastCustomers.map((item: PastCustomerInfoProps, index)=>(
                <div key={`pastCustomers${index}`}>
                    {eMode.pastCustomers[index].edit && 
                        <>
                            <ModalHeader toggle={handleClose}>{`過去の契約者情報 (${index+1})`}</ModalHeader>
                            <ModalBody>
                                <div className='row grid d-flex justify-content-center'>
                                    <div className='col-12 m-2 py-2'>
                                        <LabelDecoration label={"名前"} />
                                        <div className="row grid d-flex justify-content-center mt-1">
                                            <div className="col-6 g-col-6">
                                                <Input
                                                    id="firstName"
                                                    name="firstName"
                                                    type="text"
                                                    value={item.firstName}
                                                    onChange={(e)=>{
                                                        const updatedPastCustomers = [...editRow.pastCustomers];
                                                        updatedPastCustomers[index].firstName = e.target.value;
                                                        setEditRow({ ...editRow, pastCustomers: updatedPastCustomers });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 g-col-6">
                                                <Input
                                                    id="lastName"
                                                    name="lastName"
                                                    type="text"
                                                    value={item.lastName}
                                                    onChange={(e)=>{
                                                        const updatedPastCustomers = [...editRow.pastCustomers];
                                                        updatedPastCustomers[index].lastName = e.target.value;
                                                        setEditRow({ ...editRow, pastCustomers: updatedPastCustomers });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 m-2 py-2'>
                                        <LabelDecoration label={"フリガナ"} />
                                        <div className="row grid d-flex justify-content-center mt-1">
                                            <div className="col-6 g-col-6">
                                                <Input
                                                    id="furiganaFirstName"
                                                    name="furiganaFirstName"
                                                    type="text"
                                                    value={item.furiganaFirstName}
                                                    onChange={(e)=>{
                                                        const updatedPastCustomers = [...editRow.pastCustomers];
                                                        updatedPastCustomers[index].furiganaFirstName = e.target.value;
                                                        setEditRow({ ...editRow, pastCustomers: updatedPastCustomers });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 g-col-6">
                                                <Input
                                                    id="furiganaLastName"
                                                    name="furiganaLastName"
                                                    type="text"
                                                    value={item.furiganaLastName}
                                                    onChange={(e)=>{
                                                        const updatedPastCustomers = [...editRow.pastCustomers];
                                                        updatedPastCustomers[index].furiganaLastName = e.target.value;
                                                        setEditRow({ ...editRow, pastCustomers: updatedPastCustomers });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            
                        </>
                    }
                </div>
            ))}
            { eMode.address &&
                <>
                    <ModalHeader toggle={handleClose}>{`住所`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"住所"} />
                                <div className="mt-1">
                                    <Input
                                        id="address"
                                        name="address"
                                        type="text"
                                        value={editRow.address}
                                        onChange={(e)=>{setEditRow({...editRow, address: e.target.value})}}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            { eMode.zipCode &&
                <>
                    <ModalHeader toggle={handleClose}>{`郵便番号`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"郵便番号"} />
                                <div className="mt-1">
                                    <Input
                                        id="zipCode"
                                        name="zipCode"
                                        type="text"
                                        value={editRow.zipCode}
                                        onChange={(e)=>{setEditRow({...editRow, zipCode: e.target.value})}}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            { eMode.googleMapCode &&
                <>
                    <ModalHeader toggle={handleClose}>{`GoogleMap`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"GoogleMap"} />
                                <div className="mt-1">
                                    <Input
                                        id="googleMapCode"
                                        name="googleMapCode"
                                        type="text"
                                        value={editRow.googleMapCode}
                                        onChange={(e)=>{setEditRow({...editRow, googleMapCode: e.target.value})}}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            { eMode.phoneNumber &&
                <>
                    <ModalHeader toggle={handleClose}>{`電話番号`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"電話番号"} />
                                <div className="mt-1">
                                    <Input
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        type="number"
                                        value={editRow.phoneNumber}
                                        onChange={e => setEditRow({...editRow, phoneNumber: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            { eMode.contractDate &&
                <>
                    <ModalHeader toggle={handleClose}>{`契約日`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"契約日"} />
                                <div className="mt-1">
                                    <DatePicker
                                        dateFormat="yyyy/MM/dd"
                                        selected={editRow.contractDate}
                                        onChange={(date) => setEditRow({...editRow, contractDate: date})}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            { eMode.constructionDate &&
                <>
                    <ModalHeader toggle={handleClose}>{`工事日`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"工事日"} />
                                <div className="mt-1">
                                    <DatePicker
                                        dateFormat="yyyy/MM/dd"
                                        selected={editRow.constructionDate}
                                        onChange={(date) => setEditRow({...editRow, constructionDate: date})}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            { eMode.category &&
                <>
                    <ModalHeader toggle={handleClose}>{`カテゴリ`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"カテゴリ"} />
                                <div className="mt-1">
                                    <Input
                                        id="category"
                                        name="category"
                                        type="select"
                                        className='c_select'
                                        value={editRow.category}
                                        onChange={(e)=>{setEditRow({...editRow, category: e.target.value})}}
                                    >
                                        <option value={'工事'}>工事</option>
                                        <option value={'メンテナンス'}>メンテナンス</option>
                                        <option value={'点検のみ'}>点検のみ</option>
                                        <option value={'未訪問'}>未訪問</option>
                                        <option value={'自社工事'}>自社工事</option>
                                        <option value={'自社工事＋メンテナンス'}>自社工事＋メンテナンス</option>
                                        <option value={'他社入替'}>他社入替</option>
                                    </Input>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            { eMode.salesType &&
                <>
                    <ModalHeader toggle={handleClose}>{`販売種別`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"販売種別"} />
                                <div className="mt-1">
                                    <Input
                                        id="salesType"
                                        name="salesType"
                                        type="select"
                                        className='c_select'
                                        value={editRow.salesType}
                                        onChange={(e)=>{setEditRow({...editRow, salesType: e.target.value})}}
                                    >
                                        <option value={''}></option>
                                        <option value={'エコキュート単体'}>エコキュート単体</option>
                                        <option value={'電化セット / 太陽光発電'}>電化セット / 太陽光発電</option>
                                        <option value={'電化セット'}>電化セット</option>
                                        <option value={'太陽光'}>太陽光</option>
                                        <option value={'IH'}>IH</option>
                                        <option value={'エコキュート単体 / 太陽光'}>エコキュート単体 / 太陽光</option>
                                    </Input>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            { eMode.ECFAddedDate &&
                <>
                    <ModalHeader toggle={handleClose}>{`エコキュートの型式`}</ModalHeader>
                    <ModalBody>
                        { editRow.ECFAddedDate.map((item:ECFAddedDateProps, index)=>(
                            <div key={`updateAt${index}`} className='row grid d-flex justify-content-center'>
                                <div className='col-12 m-2 py-2'>
                                    <LabelDecoration label={`エコキュートの型式 (${index+1})`} />
                                    <div className="mt-1">
                                        { !ecocuteType[index].type ? 
                                            <Input
                                                id={`ecocuteModel${index}`}
                                                name={`ecocuteModel${index}`}
                                                type="select"
                                                className='c_select'
                                                value={item.ecocuteModel}
                                                onChange={(e)=>{
                                                    const updatedECFAddedDate = [...editRow.ECFAddedDate];
                                                    updatedECFAddedDate[index].ecocuteModel = e.target.value;
                                                    setEditRow({ ...editRow, ECFAddedDate: updatedECFAddedDate });
                                                }}
                                            >
                                                <option value={''}></option>
                                                <option value={'EQ37HFV'}> EQ37HFV</option>
                                                <option value={'EQ46HFV'}> EQ46HFV</option>
                                                <option value={'EQ46JFV'}> EQ46JFV</option>
                                                <option value={'EQ37JFV'}> EQ37JFV</option>
                                                <option value={'EQ37KFV'}> EQ37KFV</option>
                                                <option value={'EQ46KFV'}> EQ46KFV</option>
                                                <option value={'EQ46LFV'}> EQ46LFV</option>
                                                <option value={'EQ37LFV'}> EQ37LFV</option>
                                            </Input>:
                                            <Input
                                                id={`ecocuteModel_${index}`}
                                                name={`ecocuteModel_${index}`}
                                                type="text"
                                                value={item.ecocuteModel}
                                                onChange={e => {
                                                    const updatedECFAddedDate = [...editRow.ECFAddedDate];
                                                    updatedECFAddedDate[index].ecocuteModel = e.target.value;
                                                    setEditRow({ ...editRow, ECFAddedDate: updatedECFAddedDate });
                                                }}
                                            />
                                        }
                                    </div>
                                    <div className='d-flex flex-row mt-2'>
                                        <Label check>手動で入力する</Label>&nbsp;&nbsp;
                                        <FormGroup switch>
                                            <Input
                                                type="switch"
                                                checked={ecocuteType[index]?.type || false}
                                                onChange={(e) => {
                                                    const eType = e.target.checked;
                                                    const temp = [...ecocuteType]; // Create a shallow copy of the state array
                                                    temp[index] = { type: eType };
                                                    setEcocuteType(temp);
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className='col-12 m-2 py-2'>
                                    <LabelDecoration label={"更新日"} />
                                    <div className="mt-1">
                                        <DatePicker
                                            id={`updateAt${index}`}
                                            name={`updateAt${index}`}
                                            dateFormat="yyyy/MM/dd"
                                            selected={item.updateAt}
                                            onChange={(date) => {
                                                const newUpdateDates = [...editRow.ECFAddedDate];
                                                newUpdateDates[index].updateAt = date;
                                                setEditRow({ ...editRow, ECFAddedDate: newUpdateDates });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ModalBody>
                </>
            }
            { eMode.note &&
                <>
                    <ModalHeader toggle={handleClose}>{`備考`}</ModalHeader>
                    <ModalBody>
                        <div className='row grid d-flex justify-content-center'>
                            <div className='col-12 m-2 py-2'>
                                <LabelDecoration label={"備考"} />
                                <div className="mt-1">
                                    <Input
                                        id="note"
                                        name="note"
                                        type="textarea"
                                        value={editRow.note}
                                        onChange={(e)=>{setEditRow({...editRow, note: e.target.value})}}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
            {/* modal footer */}
            <ModalFooter>
                <Button color="primary" onClick={()=>handleSave(editRow)}>保存</Button>{' '}
                <Button color="secondary" onClick={handleClose}>キャンセル</Button>
            </ModalFooter>
        </Modal>
    )
}

export default CustomerEditModel;