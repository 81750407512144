import React, { useState, useEffect } from 'react';
import { DefaultTemplate } from 'components/templates';
import { Col, Container, Row, Input, Button, RowProps } from 'reactstrap';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Widget, FilterModel } from 'components/organisms';
import { CustomerManagementHelper } from "helper";

export interface CustomerListProps {}
interface FilterModelProps {
    open: boolean;
    keyword: string;
    category: string;
    plusCode: string;
    bookmark: string;
};

interface PastCustomerInfoProps {
    firstName: string;
    lastName: string;
    furiganaFirstName: string;
    furiganaLastName: string;
}

interface ECFAddedDateProps {
    ecocuteModel: string;
    updateAt: Date | null; // Allow null
}

interface FileProps {
    id: string;
    filePath: string;
    originalName: string;
    createdAt: Date | null; // Allow null
}

interface RowsProps {
    id:string;
    firstName: string;
    lastName: string;
    furiganaFirstName: string;
    furiganaLastName: string;
    pastCustomers: Array<PastCustomerInfoProps>;
    address: string;
    googleMapCode: string;
    phoneNumber: string;
    contractDate: Date | null; // Allow null
    constructionDate: Date | null; // Allow null
    category: string;
    salesType: string;
    ECFAddedDate: Array<ECFAddedDateProps>;
    pdfFiles: Array<FileProps>;
    imageFiles: Array<FileProps>;
    note: string;
    plusCode: string;
    bookmark: string;
}

const CustomerList: React.FC<CustomerListProps> = () => {
    const [ rows, setRows ] = useState<RowsProps[]>([]);

    // filter model
    const [ filterModel, setFilterModel ] = useState<FilterModelProps>({
        open: false, 
        keyword:"",
        category: "",
        plusCode: "",
        bookmark: "",
    });

    const closeFilterModel = () => {
        setFilterModel({ ...filterModel, open: false });
    }

    const updateFilterModel = (e: FilterModelProps) => {
        setFilterModel({ ...e, open: false });
    }

    const handleFilterModel = () => {
        setFilterModel({ ...filterModel, open: true });
    }
    // filter model close

    useEffect(()=>{
        if(!filterModel.open){
            CustomerManagementHelper.getAll(filterModel)
            .then((res:any)=>{
                const { rows } = res.data;
                setRows(rows)
            })
            .catch((err: any)=>{
                window.location.href = '/logout';
            })
        }
    },[filterModel]);

    const handlePlusCode = (item: RowProps) => {
        const data = { ...item, plusCode: (item.plusCode==="選択済み"? "選択なし": "選択済み") }
        updateRow(data);
    }

    const handleBookmark = (item: RowProps) => {
        const data = { ...item, bookmark: (item.bookmark==="選択済み"? "選択なし": "選択済み") }
        updateRow(data);
    }

    const updateRow = (data: RowProps) => {
        CustomerManagementHelper.updateRow(data, filterModel)
            .then((res:any)=>{
                const { rows } = res.data;
                setRows(rows)
            })
            .catch((err: any)=>{
                window.location.href = '/logout';
            })
    }

    const handleDetail = (item: RowProps) => {
        window.location.href= `/detail/${item.id}`
    }

    const compareByKana = (a: RowsProps, b: RowsProps) => {
        const collator = new Intl.Collator("ja", { sensitivity: "base" });
        const nameA = a.furiganaFirstName + a.furiganaLastName;
        const nameB = b.furiganaFirstName + b.furiganaLastName;
        return collator.compare(nameA, nameB);
    };

    return <DefaultTemplate>
        <Container className="px-sm-5 px-2 py-2" fluid>
            <div className='font_24'>{`「${rows.length}件」の顧客データ`}</div>
            <Row className='mb-3'>
                <Col className='mt-3' sm='12' md='6' xl='8'>
                    <div className='col-sm-12 col-md-12 col-lg-9'>
                        <Input 
                            className='c_filter' 
                            placeholder='名前、または住所で検索' 
                            value={filterModel.keyword}
                            onChange={(e)=>{
                                setFilterModel({ ...filterModel, keyword: e.target.value })
                            }}
                        />
                    </div>
                </Col>
                <Col className='mt-3' sm='12' md='6' xl='4'>
                    <div className='d-flex flex-row-reverse font_24'>
                        <Button className='text-dark border-0' style={{ backgroundColor: "white"}} onClick={handleFilterModel}>絞り込み&nbsp;&nbsp;<FontAwesomeIcon icon={faChevronRight} /></Button>
                    </div>
                </Col>
            </Row>
            <Row>
                { rows.sort(compareByKana).map((item: RowsProps)=>(
                    <Col key={item.id} xl="4" md="6" sm='12' className='my-3'>
                        <Widget
                            bg="white"
                            className="my-4"
                            text="#333333"
                            surname1={`${item.firstName} ${item.lastName}`}
                            surname2={`${item.furiganaFirstName} ${item.furiganaLastName}`}
                            pastCustomers={item.pastCustomers}
                            address={item.address}
                            plusCode={item.plusCode}
                            bookmark={item.bookmark}
                            handleDetail={()=>handleDetail(item)}                 
                            handlePlusCode={()=>handlePlusCode(item)}                 
                            handleBookmark={()=>handleBookmark(item)}                 
                        />
                    </Col>
                ))}
            </Row>
        </Container>
        <FilterModel
            open={filterModel.open}
            keyword={filterModel.keyword}
            category={filterModel.category}
            plusCode={filterModel.plusCode}
            bookmark={filterModel.bookmark}
            handleClose={closeFilterModel} 
            handleUpdate={updateFilterModel}
        />
    </DefaultTemplate>
};

export default CustomerList;
