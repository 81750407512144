import React, { useState } from "react";
// import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

export interface CustomizeAddButtonProps {
    id: string,
    text: string,
    onClick: () => void;
};

const CustomizeAddButton: React.FC<CustomizeAddButtonProps> = ({id, text, onClick }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return <div className='row grid d-flex justify-content-center my-2'>
        <div className='d-flex justify-content-center'>
            <FontAwesomeIcon 
                id={ id } 
                icon={faAdd} 
                className='fs-3 border border-dark-subtle p-3 add_btn' 
                style={{ backgroundColor: '#F4F4F4', cursor: 'pointer' }} 
                onClick={onClick}
            />
            {/* <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={id}
                toggle={toggle}
            >
                {text}
            </Tooltip> */}
        </div>
    </div>
}

export default CustomizeAddButton;