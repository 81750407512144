import React from 'react';
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

interface AuthContextProps {
    user: any | null;
    login: (user: any, callback: VoidFunction) => void;
    register: (user: any, callback: VoidFunction) => void;
    forgotPassword: (user: any, callback: VoidFunction) => void;
    logout: (callback: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps);

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = props => {
    const storedObj = localStorage.getItem('customer-user');
    let tempUser: { id: string, username: string, email: string } | null = null;
    if (storedObj !== null) {
        try {
            tempUser = JSON.parse(storedObj);
        } catch (error) {
          console.error('Error parsing stored object:', error);
        }
    }
    const [user, setUser] = React.useState<any>(tempUser);

    const login = async (loginInfo: any, callback: VoidFunction): Promise<void> => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PATH}/auth/login`, loginInfo);
            const { user , token, msg } = response.data;
            localStorage.setItem('customer-user', JSON.stringify(user));
            localStorage.setItem('customer-token', token);
            setUser(user);
            callback();
        } catch (error: any) {
            console.log(error?.response.data.error);
        }
    };

    const register = async (registerInfo: any, callback: VoidFunction): Promise<void> => {
        const response = await axios.post(`${process.env.REACT_APP_API_PATH}/auth/register`, registerInfo);
        const { user , accessToken } = response.data;
        setUser(user);
        callback();
    };

    const logout = (callback: VoidFunction): void => {
        localStorage.setItem('customer-user', JSON.stringify(null));
        setUser(null);
        callback();
    };

    const forgotPassword = async (mail: string, calback: VoidFunction): Promise<void> => {
        try {
            await axios.post(`${process.env.REACT_APP_API_PATH}/auth/forgotPassword`, mail);
            calback();
        } catch (error) {
            
        }
    }

    return (
        <AuthContext.Provider value={{ user, login, logout, register, forgotPassword }}>
            {props.children}
        </AuthContext.Provider>
    );
};
