import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from 'reactstrap';
import { LabelDecoration } from "components/organisms";

export interface CreateProps {
    data: {
        id: string;
        isNew: boolean;
        isDelete: boolean;
        email: string;
        username: string;
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
    };
    open: boolean;
    closeCreateOrEdit: () => void;
    saveCreateOrEdit: (data: any) => void;
}

const CreateOrEdit: React.FC<CreateProps> = (props: any) => {
    const { data, open } = props;
    const [ modal, setModal ] = useState(true);
    const [ flag, setFlag ] = useState(true);
    const [ emailError, setEmailError ] = useState(false);
    const [ userNameError, setUserNameError ] = useState(false);
    const [ oldPasswordError, setOldPasswordError ] = useState(false);
    const [ newPasswordError, setNewPasswordError ] = useState(false);
    const [ confirmPasswordError, setConfirmPasswordError ] = useState(false);
    const [ tempData, setTempData ] = useState({
        id: "",
        isNew: false,
        isDelete: false, 
        email: "",
        username : "",
        oldPassword : "",
        newPassword : "",
        confirmPassword : "",
        password : "",
    });
    useEffect(()=>{
        setModal(open);
        setTempData(data);
        setEmailError(false);
        setUserNameError(false);
        setOldPasswordError(false);
        setNewPasswordError(false);
        setConfirmPasswordError(false);
    },[open]);

  const handleClose = () => {
    props.closeCreateOrEdit();
  }

  const handleSave = () => {
    if (validateInputs()) {
      props.saveCreateOrEdit(tempData);
    }
  }

  const validateInputs = () => {
    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(tempData.email);
    let status =  true;
    if(!isValidEmail) {
      setEmailError(!isValidEmail);
      status = false;
    }else setEmailError(false);
    if(!tempData.username){
      setUserNameError(true);
      status = false;
    }else setUserNameError(false);

    if(tempData.isNew) {
      if(!tempData.newPassword){
        setNewPasswordError(true);
        status = false;
      }else setNewPasswordError(false);
      if(tempData.newPassword !== tempData.confirmPassword || !tempData.confirmPassword){
        setConfirmPasswordError(true);
        status = false;
      }else setConfirmPasswordError(false);
    } else {
      if(!tempData.oldPassword && (!!tempData.newPassword || !!tempData.confirmPassword)){
        setOldPasswordError(true);
        status = false;
      }else setOldPasswordError(false);
      if(!tempData.newPassword && !!tempData.oldPassword){
        setNewPasswordError(true);
        status = false;
      }else setNewPasswordError(false);
      if(tempData.newPassword !== tempData.confirmPassword){
        setConfirmPasswordError(true);
        status = false;
      }else setConfirmPasswordError(false);
    }
    setFlag(!flag);
    if(status) return true;
    else return false;
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleClose}
        className={"modal-fullscreen-sm-down modal-dialog-centered"}
        backdrop={"static"}
        keyboard={true}
      >
        <ModalHeader className='d-flex justify-content-center border-0 pb-0 m-4'>新規登録</ModalHeader>
        <ModalBody className='mx-4 p-0'>
            <div className='col-12 py-2'>
                <LabelDecoration label={"ユーザーID（メールアドレス）"} />
                <div className="row grid d-flex justify-content-center m-1">
                  <Input 
                    id="email"
                    name="email"
                    type="email"
                    invalid={!!emailError}
                    // defaultValue={""}
                    value={tempData.email}
                    disabled={tempData.isDelete}
                    onChange={(e)=>{setTempData({...tempData, email: e.target.value})}}
                  />
                  {emailError && <div className="invalid-feedback">正しい電子メール形式を入力してください。</div>}
                </div>
            </div>
            <div className='col-12 py-2 mt-1'>
                <LabelDecoration label={"お名前"} />
                <div className="row grid d-flex justify-content-center m-1">
                    <Input
                        id="username"
                        name="username"
                        invalid={!!userNameError}
                        type="text"
                        disabled={tempData.isDelete}
                        value={tempData.username}
                        // defaultValue={""}
                        onChange={(e)=>{setTempData({...tempData, username: e.target.value})}}
                    />
                    {userNameError && <div className="invalid-feedback">あなたの名前を入力してください。</div>}
                </div>
            </div>
            { !tempData.isNew &&
              <div className='col-12 py-2 mt-1'>
                  <LabelDecoration label={"以前のパスワード"} />
                  <div className="row grid d-flex justify-content-center m-1">
                      <Input
                          id="oldPassword"
                          name="oldPassword"
                          invalid={!!oldPasswordError}
                          type="password"
                          disabled={tempData.isDelete}
                          // defaultValue={""}
                          value={tempData.oldPassword}
                          onChange={(e)=>{setTempData({...tempData, oldPassword: e.target.value})}}
                      />
                      {oldPasswordError && <div className="invalid-feedback">パスワードを変更するには、古いパスワードを入力してください。</div>}
                  </div>
              </div>
            }
            <div className='col-12 py-2 mt-1'>
                <LabelDecoration label={"パスワード"} />
                <div className="row grid d-flex justify-content-center m-1">
                    <Input
                        id="newPassword"
                        name="newPassword"
                        invalid={!!newPasswordError}
                        type="password"
                        disabled={tempData.isDelete}
                        // defaultValue={""}
                        value={tempData.newPassword}
                        onChange={(e)=>{setTempData({...tempData, newPassword: e.target.value})}}
                    />
                    {newPasswordError && <div className="invalid-feedback">新しいパスワードを入力してください。</div>}
                </div>
            </div>
            <div className='col-12 py-2 mt-1'>
                <LabelDecoration label={"パスワードを確認します"} />
                <div className="row grid d-flex justify-content-center m-1">
                    <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        invalid={!!confirmPasswordError}
                        type="password"
                        disabled={tempData.isDelete}
                        // defaultValue={""}
                        value={tempData.confirmPassword}
                        onChange={(e)=>{setTempData({...tempData, confirmPassword: e.target.value})}}
                    />
                    {confirmPasswordError && <div className="invalid-feedback">確認パスワードが間違っています</div>}
                </div>
            </div>
        </ModalBody>
        <ModalFooter className='mx-3 py-4 border-0 justify-content-between'>
            <div className="col-6 g-col-6 m-0" style={{ paddingRight: 4 }}>
                <Button color="secondary" className='col-12' outline onClick={handleClose}>キャンセル</Button>
            </div>
            <div className="col-6 g-col-6 m-0" style={{paddingLeft: 4 }}>
                <Button color="primary" className='col-12' onClick={handleSave}>{tempData.isDelete?"削除":"保存"}</Button>
            </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CreateOrEdit;