import React from "react";
import { LabelwithButtonDecoration } from "components/organisms";

export interface DetailItemProps {
    label: string;
    text: string;
    editClick: ()=>void;
}

const DetailItem: React.FC<DetailItemProps> = ({ label, text, editClick}) => {
    return (
        <div className='row grid d-flex justify-content-center'>
            <div className='col-12 m-2'>
                <LabelwithButtonDecoration label={label} btnLabel={"編集"} handleEdit={editClick} />
                <div className="mt-2">{text}</div>
            </div>
        </div>
    )
}

export default DetailItem;