import React from 'react';
import type { PasswordRecoveryFormHelpers, PasswordRecoveryFormValues } from 'components/organisms';
import { ForgotPasswordPage } from 'components/pages';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
    const initialPasswordRecoveryFormValues: PasswordRecoveryFormValues = {
        email: ''
    };

    const onPasswordRecoveryFormSubmit = async (
        values: PasswordRecoveryFormValues,
        __: PasswordRecoveryFormHelpers
    ): Promise<void> => {
        try {
            await axios.post(`${process.env.REACT_APP_API_PATH}/auth/forgotPassword`, values);
            toast.success("メールが送信されました。", {
                position: "top-center",
                autoClose: 1000,
            });
        } catch (error) {
            toast.error("これは登録されたメールアドレスではありません。", {
                position: "top-center",
                autoClose: 1000,
            });
        }
        // forgotPassword(values, () => {});
    };

    return (
        <>
            <ForgotPasswordPage
                {...{ initialPasswordRecoveryFormValues, onPasswordRecoveryFormSubmit }}
            />
            <ToastContainer />
        </>
    );
};

export default ForgotPassword;
