import React from 'react';
import type { ResetFormHelpers, ResetFormValues } from 'components/organisms';
import { ResetPage } from 'components/pages';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface RegisterProps {}

const Register: React.FC<RegisterProps> = () => {
    const initialRegisterFormValues: ResetFormValues = {
        token: "",
        password: '',
        passwordConfirm: ''
    };

    const onRegisterFormSubmit = async (values: ResetFormValues, __: ResetFormHelpers): Promise<void> => {
        if(!values.password ||!values.passwordConfirm || values.password !== values.passwordConfirm){
            toast.error(`パスワードをご確認ください。`, {
                position: "top-center",
                autoClose: 600,
            });
        }
        if(values.password.length >5 ){
            toast.error(`少なくとも6文字を入力してください`, {
                position: "top-center",
                autoClose: 600,
            });
        }
        try {
            const resut = await axios.post(`${process.env.REACT_APP_API_PATH}/auth/resetPassword`, values);
            console.log(resut.data.msg);
            toast.success(`パスワードが変更されました。`, {
                position: "top-center",
                autoClose: 600,
            });
            } catch (error) {
            toast.error(`トークンの有効期限が切れました。`, {
                position: "top-center",
                autoClose: 600,
            });
            
        }
    };

    return <>
        <ResetPage {...{ initialRegisterFormValues, onRegisterFormSubmit }} />
        <ToastContainer />
    </>
};

export default Register;
