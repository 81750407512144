import React from "react";
export interface LabelwithButtonDecorationProps {
    label: string;
    btnLabel: string;
    handleEdit: ()=> void;
}

const LabelwithButtonDecoration: React.FC<LabelwithButtonDecorationProps> = ({label, btnLabel, handleEdit}) => {
    return (
        <div className="d-flex" style={{ margin: 4}}>
            <div style={{ borderStyle: "solid", borderWidth: "0px 0px 0px 5px", borderColor: "#2E87FB", paddingLeft: "4px" }}>{ label }</div>
            <div 
                className="border border-info border-1 mx-2 px-1 bg-light" 
                style={{cursor: "pointer"}}
                onClick={handleEdit}
            >{btnLabel}</div>
        </div>
    );
}

export default LabelwithButtonDecoration;