import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface PDFViewerProps {
    open: boolean;
    title: string;
    pdfFile: File|null;
    handleClose: () => void;
};

const PDFViewer: React.FC<PDFViewerProps> = ({open, title, pdfFile, handleClose}) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [flag, setFlag] = useState<boolean>(false);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setFlag(true);
        setNumPages(numPages);
    }

    const nextPage = () => { 
        if(flag){
            setFlag(false);
            setPageNumber((pre)=>pre+1) ;
        }
    };
    const prePage = () => { 
        if(flag){
            setFlag(false);
            setPageNumber((pre)=>pre-1);
        }
    };
    return <div>
        <Modal isOpen={open} toggle={handleClose} fullscreen>
            <ModalHeader toggle={handleClose}>
                {title}
            </ModalHeader>
            <ModalBody style={{ overflowY: "auto"}} className="border">
                { !!pdfFile && 
                    <Document file={URL.createObjectURL(pdfFile)} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                }
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Button color="dark" disabled={pageNumber===1} onClick={prePage}>戻る</Button>
                {`${pageNumber} / ${numPages}`}
                <Button color="dark" disabled={pageNumber===numPages} onClick={nextPage}>次に</Button>
            </ModalFooter>
        </Modal>
    </div>
}

export default PDFViewer;