import axios from 'axios';

const setSession = (accessToken: any) => {
    if (accessToken) {
      localStorage.setItem('customer-token', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('customer-token');
      delete axios.defaults.headers.common.Authorization;
    }
};

const getAll = (filter: any) => {
  const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/customerdata/getAll`, filter);
}

const getRow = (id: string) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/customerdata/getRow`, {id});
}

const updateRow = (data: any, filterModel: any) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/customerdata/update`, { data, filterModel});
}

const deleteRow = (id: string) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/customerdata/deleteRow`, {id});
}

const updateRowOne = (data: any) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/customerdata/updateOne`, data);
}

const deleteFile = (tempRow: any, filePath: string, type: string) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/customerdata/deleteFile`, {tempRow, filePath, type});
}

const uploadFile = (temp: string, name: string, type: string) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/customerdata/uploadFile`, {temp, name, type});
}

const createCustomerData = (data: any) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/customerdata/create`, data);
}

const contractDocumentSave = (data: any) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/contract/doc/save`, data);
}

const deleteContractDocument = (data: any) => {
    const accessToken = localStorage.getItem("customer-token");
    setSession(accessToken);
    return axios.post(`${process.env.REACT_APP_API_PATH}/contract/doc/delete`, data);
}

const CustomerManagementHelper = {
  getAll,
  getRow,
  deleteRow,
  updateRow,
  updateRowOne,
  deleteFile,
  uploadFile,
  createCustomerData,
  contractDocumentSave,
  deleteContractDocument
}

export default CustomerManagementHelper;