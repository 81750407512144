import React from 'react';
import type { PasswordRecoveryFormHelpers, PasswordRecoveryFormValues } from 'components/organisms';
import { PasswordRecoveryForm } from 'components/organisms';
import { AuthenticationTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';

export interface ForgotPasswordPageProps {
    initialPasswordRecoveryFormValues: PasswordRecoveryFormValues;
    onPasswordRecoveryFormSubmit: (
        values: PasswordRecoveryFormValues,
        helpers: PasswordRecoveryFormHelpers
    ) => void;
}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = props => (
    <AuthenticationTemplate>
        <div className='m-3'>
            <img src={process.env.REACT_APP_LOGO} style={{ marginLeft: 15, marginTop:15 }} width={"40px"} alt='logo' />
        </div>
        <Container>
            <Row className="justify-content-center">
                <Col lg="5">
                    <Card className="shadow-lg border-0 mt-5">
                        <CardHeader className='border-0 bg-white'>
                            <h3 className="text-center font-weight-light my-4">
                                パスワードをリセット
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <div className="small mb-3 text-muted">
                                メールアドレスを入力してください。パスワードをリセットするためのリンクが送信されます。
                            </div>
                            <PasswordRecoveryForm
                                initialValues={props.initialPasswordRecoveryFormValues}
                                onSubmit={props.onPasswordRecoveryFormSubmit}
                            />
                        </CardBody>
                        <CardFooter className="text-center py-3 border-0 bg-white">
                            <Link className="small" to="/login">
                                ログインに戻る
                            </Link>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
    </AuthenticationTemplate>
);

export default ForgotPasswordPage;
