import React, { useState, useEffect } from 'react';
import { DefaultTemplate } from 'components/templates';
import { Container, Button, Table } from 'reactstrap';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateOrEditAccount  } from 'components/organisms';
import { AccountManagementHelper } from "helper/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { v4: uuidv4 } = require('uuid');

export interface AccountManagementPageProps {};

const AccountManagementPage: React.FC<AccountManagementPageProps> = () => {
    const [ rows, setRows ] = useState([]);
    useEffect(()=>{
        AccountManagementHelper.getAll()
            .then((res: any) => {
                setRows(res.data);
            })
            .catch((err: any) => {
                window.location.href = '/logout';
            });
    }, [])
    const [ tempData, setTempData ] = useState({
        id: "",
        isNew: false,
        isDelete: false,
        email: "",
        username : "",
        oldPassword : "",
        newPassword : "",
        confirmPassword : "",
    });

    const [ modal, setModal ] = useState(false);
    // new account modal
    const newAccount = () => {
        setTempData({
            id: uuidv4(),
            isNew: true,
            isDelete: false,
            email: "",
            username : "",
            oldPassword : "",
            newPassword : "",
            confirmPassword : "",
        });
        setModal(true);
    }
    // close modal
    const closeCreateOrEdit = () => {
        setTempData({
            id: "",
            isNew: true,
            isDelete: false,
            email: "",
            username : "",
            oldPassword : "",
            newPassword : "",
            confirmPassword : "",
        });
        setModal(false);
    }

    const saveCreateOrEdit = (data: any) => {
        if( !data.isDelete ){
            if(!data.email || !data.username){
                toast.error("入力値を確認してください！", {
                    position: "top-center"
                });
                return;
            }
            AccountManagementHelper.saveData(data)
                .then((res: any) => {
                    const { flag, data, msg } = res.data;
                    if(flag){
                        setRows(data);
                        toast.success(msg, {
                            position: "top-center"
                        });
                        setModal(!flag);
                    } else {
                        toast.error(msg, {
                            position: "top-center"
                        });
                    }
                })
                .catch((err: any) => {
                    window.location.href = '/logout';
                });
        } else {
            AccountManagementHelper.deleteData(data)
                .then((res: any) => {
                    const { flag, data } = res.data;
                    if(flag){
                        setRows(data);
                        toast.success("削除されました！", {
                            position: "top-center"
                        });
                    } else {
                        toast.error("エラーが発生しました。 開発者にお問い合わせください！", {
                            position: "top-center"
                        });
                    }
                    setModal(!flag);
                })
                .catch((err: any) => {
                    window.location.href = '/logout';
                });
        }
    } 

    const editData = (data: any) => {
        setTempData({
            id: data.id,
            isNew: false,
            isDelete: false,
            email: data.email,
            username : data.username,
            oldPassword : "",
            newPassword : "",
            confirmPassword : "",
        });
        setModal(true);
    }

    const deleteData = (data: any) => {
        if( !data.editable ){
            toast.error("基本ユーザ情報は削除できません。", {
                position: "top-center"
            });
        } else {
            setTempData({
                id: data.id,
                isNew: false,
                isDelete: true,
                email: data.email,
                username : data.username,
                oldPassword : "",
                newPassword : "",
                confirmPassword : "",
            });
            setModal(true);
        }
    }
    
    return (
        <DefaultTemplate>
            <Container>
                <div className='row grid d-flex justify-content-center'>
                    <div className="d-grid">
                        <Button color="primary" onClick={newAccount}>新規登録</Button>
                    </div>
                    <div className='col-12 m-2 mt-4 py-2 d-grid'>
                        <Table bordered hover responsive className='text-center'>
                            <thead>
                                <tr className="table-dark">
                                    <th>ユーザーID</th>
                                    <th>名前</th>
                                    <th style={{ minWidth: "50px"}}>編集</th>
                                    <th style={{ minWidth: "50px"}}>削除</th>
                                </tr>
                            </thead>
                            <tbody>
                                { !!rows.length && rows.map((item: any)=>(
                                    <tr key={item?.id}>
                                        <th scope="row">{item.email}</th>
                                        <td>{item.username}</td>
                                        <td className={!item.editable?"text-secondary":""} onClick={()=>{editData(item)}}><FontAwesomeIcon icon={faEdit}/></td>
                                        <td className={!item.editable?"text-secondary":""} onClick={()=>{deleteData(item)}}><FontAwesomeIcon icon={faTrash}/></td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Container>
            <CreateOrEditAccount 
                data={tempData} 
                open={modal} 
                closeCreateOrEdit={closeCreateOrEdit} 
                saveCreateOrEdit={saveCreateOrEdit} 
            />
            <ToastContainer />
        </DefaultTemplate>
    );
}

export default AccountManagementPage;