import React from 'react';
import { useLocation } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'hooks';
import { Link } from 'react-router-dom';
import {
    NavbarToggler,
    Navbar,
    Nav,
    NavItem,
    NavLink,
    NavbarBrand,
} from 'reactstrap';
import { uiActions } from 'store/uiSlice';

export interface TopnavProps {}

const Topnav: React.FC<TopnavProps> = () => {
    const dispatch = useAppDispatch();

    const toggleSidenav = (): void => {
        dispatch(uiActions.toggleSetting('sidebarToggled'));
    };
    // Access the current location
    const location = useLocation();

    return (
        <>
            <Navbar color="faded" light>
                <NavbarBrand tag={Link} to="/">
                    <img src={process.env.REACT_APP_LOGO} style={{ marginLeft: 15 }} width={"40px"} alt='logo' />
                </NavbarBrand>
                <NavbarToggler className="ms-auto ms-md-0 me-3 me-lg-4 border-0" onClick={toggleSidenav}>
                    <FontAwesomeIcon icon={faBars} />
                </NavbarToggler>
            </Navbar>
            <Nav justified pills >
                <NavItem>
                    <NavLink className='c_topbar' active={location.pathname==="/"} href="/" >顧客一覧</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='c_topbar' active={location.pathname==="/add"} href="/add">新規顧客追加</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className='c_topbar' active={location.pathname==="/account"} href="/account">アカウント発行</NavLink>
                </NavItem>
            </Nav>
        </>
    );
};

export default Topnav;
